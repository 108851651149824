export class Battery {
    private initialCapacity: number;  // in mAh
    private capacity: number;  // in mAh
    private voltage: number;  // in volts
    private consumption: number;  // in amps
  
    constructor(capacity: number, voltage: number) {
      this.initialCapacity = capacity;
      this.capacity = capacity;
      this.voltage = voltage;
      this.consumption = 0;
    }
  
    // Call this method every frame with the total current being drawn by the motors
    consume(current: number, deltaTime: number) {
      // Convert current from amps to milliamps and calculate how much capacity was used
      const capacityUsed = current * 1000 * deltaTime / 3600;  // convert hours to seconds
      this.capacity -= capacityUsed;
  
      // Voltage sag depends on current draw and remaining capacity
      // This is a simple linear model, but you could use a more sophisticated model if desired
      const sag = 0.01 * current + 0.2 * (1 - this.capacity / this.initialCapacity);
      this.voltage = Math.max(this.voltage - sag, 0);  // voltage can't go below 0
  
      // Update the consumption
      this.consumption += capacityUsed;
    }
  
    getVoltage() {
      return this.voltage;
    }
  
    getRemainingCapacity() {
      return this.capacity;
    }
  
    getConsumption() {
      return this.consumption;
    }
  }
  