type ThrustDataPoint = {
    input: number;
    thrust: number;  // in grams
    rpm: number;
    amps: number;
    volts: number;
}

export class MotorInterpolator {
    private data: ThrustDataPoint[];

    constructor(data: ThrustDataPoint[]) {
        this.data = data;
    }

    private static interpolate(x: number, x0: number, x1: number, y0: number, y1: number): number {
        return y0 + (x - x0) * ((y1 - y0) / (x1 - x0));
    }

    getMotorCharacteristics(input: number, batteryVoltage: number) {
        let lowerIndex = 0;
        const percentInput = input * 100;
        for (let i = 1; i < this.data.length; i++) {
            if (this.data[i].input > percentInput) {
                break;
            }
            lowerIndex = i;
        }
        if (lowerIndex === this.data.length - 1) {
            return {
                thrust: this.data[lowerIndex].thrust,
                rpm: this.data[lowerIndex].rpm,
                amps: this.data[lowerIndex].amps,
                watts: this.data[lowerIndex].amps * batteryVoltage
            };
        }
        const lowerDataPoint = this.data[lowerIndex];
        const upperDataPoint = this.data[lowerIndex + 1];

        const interpolatedThrust = MotorInterpolator.interpolate(
            percentInput,
            lowerDataPoint.input,
            upperDataPoint.input,
            lowerDataPoint.thrust,
            upperDataPoint.thrust
        );

        const interpolatedRPM = MotorInterpolator.interpolate(
            percentInput,
            lowerDataPoint.input,
            upperDataPoint.input,
            lowerDataPoint.rpm,
            upperDataPoint.rpm
        );

        // Current depends on both input and battery voltage, so we calculate it separately
        const interpolatedAmps = MotorInterpolator.interpolate(
            percentInput,
            lowerDataPoint.input,
            upperDataPoint.input,
            lowerDataPoint.amps * lowerDataPoint.volts,
            upperDataPoint.amps * upperDataPoint.volts
        ) / batteryVoltage;

        // Power can be calculated from the current and the battery voltage
        const interpolatedWatts = interpolatedAmps * batteryVoltage;

        return {
            thrust: interpolatedThrust,
            rpm: interpolatedRPM,
            amps: interpolatedAmps,
            watts: interpolatedWatts
        };
    }
}
