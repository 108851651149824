import {
  Mesh,
  MeshBuilder,
  Scene,
  Vector3,
} from "@babylonjs/core";
import { Obstacle } from "../Obstacle";
import { GatesConstants } from "./GatesConstants";



export class TubeGate extends Obstacle {
  constructor(name: string, scene: Scene) {
    super(name, scene);
    const { height, width, depth } = GatesConstants;
    const myPath = [
      new Vector3(-width / 2 + depth / 2, 0, 0),
      new Vector3(-width / 2 + depth / 2, height - depth / 2, 0),

      new Vector3(-width / 2 + depth, height - depth / 2, 0),
      new Vector3(width / 2 - depth, height - depth / 2, 0),
      new Vector3(width / 2 - depth / 2, height - depth / 2, 0),
      new Vector3(width / 2 - depth / 2, 0, 0)

    ];

    //Create ribbon with updatable parameter set to true for later changes
    let tube = MeshBuilder.CreateTube("tube", { path: myPath, radius: depth, cap: Mesh.CAP_ALL, sideOrientation: Mesh.DOUBLESIDE, updatable: true }, scene);
    // tube.position = new Vector3(0, height / 2, 0);
    tube.setParent(this);
  }
}