import { Controller, HouseExclamation, Map } from "react-bootstrap-icons";
import { globalNavigate } from "../../pages/global-history";
import { ReactComponent as DroneIcon } from "../../styles/icons/drone.svg";
import { Rates } from "../config/rates/Rates";
import { GameMenuTabTypes } from "./GameMenu";
import { ControllerSettingsTab } from "./controller/ControllerSettings";
import { HomeTile } from "./home/HomeTile";
export const homepageMenuItems: GameMenuTabTypes[] = [
  {
    key: "home",
    type: "custom",
    title: "Home",
    url: "/",
    icon: <HouseExclamation className="icon" />,

    content: () => <HomeTile />,
  },
  {
    key: "fly",
    type: "custom",
    url: "/fly",
    icon: <DroneIcon className="icon" />,
    title: "Fly",
    content: () => <div>Play</div>,
  },
  //   {
  //     key: "achivements",
  //     type: "custom",
  //     title: "Achivements",
  //     icon: <Star className="icon" />,
  //     url: "/achivements",
  //     content: () => <div>Achivements</div>,
  //   },
  //   {
  //     key: "profile",
  //     type: "custom",
  //     title: "Profile",
  //     url: "/profile",
  //     icon: <Person className="icon" />,
  //     content: () => <div>Profile</div>,
  //   },
  {
    key: "controller",
    type: "custom",
    url: "/controller",
    icon: <Controller className="icon" />,
    title: "Controller",
    content: (focused, onUp) => (
      <ControllerSettingsTab focused={focused} onUp={onUp} />
    ),
  },
  {
    key: "rates",
    type: "custom",
    url: "/rates",
    icon: <Controller className="icon" />,
    title: "Rates",
    content: (focused, onUp) => <Rates />,
  },
  {
    type: "tiles",
    key: "tracks",
    url: "/tracks",
    title: "Tracks",
    icon: <Map className="icon" />,
    tiles: [
      {
        title: "Create new Track",
        key: "create",
        content: <div>Create new Track</div>,
        type: "tile",
        onClick: () => globalNavigate("/tracks/new"),
      },
    ],
  },
];

export const tracksMenuItems: GameMenuTabTypes[] = [];
