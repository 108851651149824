 
import { DroneData } from "../../types/drone";

export class DroneDataWrapper {
  weight: number;
  minThrust: number;
  maxThrust: number;
  constructor(private droneData: DroneData) {
    this.droneData = droneData;
    this.weight =
      (droneData.aditionalWeight +
        droneData.battery.weight +
        droneData.frame.weight +
        droneData.motor.weight * 4 +
        droneData.proppelers.weight * 4);

    this.minThrust = (droneData.thrust.idleT * 4);
    this.maxThrust = (droneData.thrust.maxT * 4);
  }
}
