import {
  MeshBuilder,
  Scene,
  Vector3
} from "@babylonjs/core";
import { materialUtil } from "../../MaterialUtil";
import { Checkpoint } from "../Checkpoint";
import { Obstacle } from "../Obstacle";
import { getGatesSizes } from "./GatesConstants";

const mgpHurdleName = (isbig: boolean) => `MGP${isbig ? 'Super' : ''}Hurdle`;
export class MGPHurdle extends Obstacle {
  constructor(name: string, scene: Scene, isBig: boolean = false) {
    super(mgpHurdleName(isBig), scene);
    const { hurdleHeight, depth, hurdleWidth } = getGatesSizes(isBig);

    const hurdle = MeshBuilder.CreateBox("flag", { width: hurdleWidth, height: hurdleHeight, depth, updatable: false }, scene);

    hurdle.position = new Vector3(0, hurdleHeight / 2, 0);
    hurdle.setParent(this);

    materialUtil.applyHurdleMaterial(hurdle, scene);
    const chekpoint = new Checkpoint("checkpoint", { shape: "square", width: hurdleWidth, height: hurdleHeight, depth }, scene);
    chekpoint.position = new Vector3(0, hurdleHeight, 0);
    chekpoint.setParent(this);
    this.checkpoints.push(chekpoint);
    this.coliders.push(hurdle);
  }
}
