import {
  MeshBuilder,
  Scene,
  Vector3
} from "@babylonjs/core";
import { uz } from "unitz-ts";
import { Checkpoint } from "../Checkpoint";
import { Obstacle } from "../Obstacle";
import { getGatesSizes } from "./GatesConstants";
import { MGPGate } from "./MGPGate";

export class MGPDiveGate extends Obstacle {
  constructor(name: string, scene: Scene, isLaunch: boolean = false) {
    super("MGPDiveGate", scene);
    const { depth, width, height: gateHeight, topPanelSize } = getGatesSizes(true);
    const totalGateHeight = gateHeight + topPanelSize;
    const height = uz(`10 ft`).convert("m").value
    const gate = new MGPGate("gate", scene, true, false, true);
    gate.setParent(this);

    if (isLaunch) {
      gate.rotate(Vector3.Left(), Math.PI / 2);
      gate.position = new Vector3(0, height, gateHeight / 2 + topPanelSize / 2);
    } else {
      gate.rotate(Vector3.Left(), -Math.PI / 2);
      gate.position = new Vector3(0, height, - gateHeight / 2 - topPanelSize / 2);
    }

    const leftFrontPipe = MeshBuilder.CreateBox("leftPipe", { width: depth, height, depth, updatable: false }, scene);
    const rightFrontPipe = MeshBuilder.CreateBox("rightPipe", { width: depth, height, depth, updatable: false }, scene);
    const leftBackPipe = MeshBuilder.CreateBox("leftPipe", { width: depth, height, depth, updatable: false }, scene);
    const rightBackPipe = MeshBuilder.CreateBox("rightPipe", { width: depth, height, depth, updatable: false }, scene);

    leftFrontPipe.setParent(this);
    rightFrontPipe.setParent(this);
    leftBackPipe.setParent(this);
    rightBackPipe.setParent(this);


    leftFrontPipe.position = new Vector3(-width / 2 + depth / 2, height / 2, - totalGateHeight / 2 + depth / 2);
    rightFrontPipe.position = new Vector3(width / 2 - depth / 2, height / 2, - totalGateHeight / 2 + depth / 2);

    leftBackPipe.position = new Vector3(-width / 2 + depth / 2, height / 2, totalGateHeight / 2 - depth / 2);
    rightBackPipe.position = new Vector3(width / 2 - depth / 2, height / 2, totalGateHeight / 2 - depth / 2);

    const helperCheckpoint = new Checkpoint(`helper:${name}`, { shape: "square", depth, height: totalGateHeight, width: width, isHelper: true }, scene);
    helperCheckpoint.position = new Vector3(0, 0, width / 2);
    // helperCheckpoint.rotation.z = Math.PI;
    helperCheckpoint.setParent(this);

    this.coliders.push(...gate.coliders);

    this.checkpoints.push(...gate.checkpoints, helperCheckpoint);
  }
}
