import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  FormControl,
  FormSelect,
  Row,
} from "react-bootstrap";
import * as uuid from "uuid";
import { Controller } from "../../../engine/gamepad/Controller";
import { ratesState } from "../../../state/RatesState";
import {
  betaflightDefault,
  defaultRates,
  suggestedRates,
} from "../../../types/defaultRates";
import {
  RateProfileType,
  RateType,
  rateTypes,
  RateValueKeys,
} from "../../../types/rates";
import { drawRateCurve, inputToDegres } from "../../../util/rates";

type AxesType = "roll" | "pitch" | "yaw";

export const Rates = () => {
  const axes: AxesType[] = ["roll", "pitch", "yaw"];
  const [addingNewProfile, setAddingNewProfile] = useState(false);
  const [newProfileName, setNewProfileName] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(ratesState.profile);
  const [copyFromId, setCopyFromId] = useState("");
  const [userProfiles, setUserProfiles] = useState(ratesState.profiles);
  const [controllerValues, setControllerValues] = useState({
    yaw: 0,
    roll: 0,
    pitch: 0,
  });

  const chartRef = useRef(null);

  const selectedRateType = rateTypes.find(
    (r) => r.key === selectedProfile?.type
  );
  const updateRateValue = (
    ax: AxesType,
    prop: RateValueKeys,
    value: number
  ) => {
    if (!selectedProfile) return;
    selectedProfile.values[ax][prop] = value;
    ratesState.updateRateProfile({ ...selectedProfile });
  };
  useEffect(() => {
    ratesState.on("profile:selected", setSelectedProfile);
    ratesState.on("profile:updated", setSelectedProfile);
    ratesState.on("profile:added", setSelectedProfile);
    ratesState.on("profiles:changed", setUserProfiles);
  });
  useEffect(() => {
    const controller = new Controller();
    const intervalID = setInterval(() => {
      const values = controller.getTRYPValues();
      if (!values) return;

      setControllerValues({
        roll: values.roll,
        pitch: values.pitch,
        yaw: values.yaw,
      });
    }, 100);
    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    const canvas = chartRef?.current;
    if (canvas) {
      const context = (canvas as HTMLCanvasElement).getContext("2d");
      const el = canvas as HTMLCanvasElement;
      if (context && selectedProfile) {
        drawRateCurve(
          selectedProfile.type,
          selectedProfile.values,
          context,
          el.width,
          el.height,
          controllerValues
        );
      }
    }
  }, [selectedProfile, chartRef, controllerValues]);

  const updateProfileType = (value: RateType) => {
    if (!selectedProfile) return;

    const rateProfile = {
      ...defaultRates[value],
      id: selectedProfile.id,
      name: selectedProfile.name,
    };

    ratesState.updateRateProfile(rateProfile);
  };

  const saveNewProfile = () => {
    const profileTemplate =
      copyFromId !== ""
        ? [...ratesState.profiles, ...suggestedRates].find(
            (p) => p.id === copyFromId
          )!
        : betaflightDefault;
    const profile: RateProfileType = {
      ...profileTemplate,
      name: newProfileName,
      id: uuid.v7(),
    };
    ratesState.addRateProfile(profile);
    ratesState.selectRateProfile(profile);

    setAddingNewProfile(false);
    setNewProfileName("");
    setCopyFromId("");
  };
  const onProfileChange = (id: string) => {
    const profile = ratesState.profiles.find((p) => p.id === id);
    if (!profile) return;
    ratesState.selectRateProfile(profile);
  };

  return (
    <div className="Rates">
      <Row className="mb-2">
        <label className="col-3 col-sm-2">Profile</label>
        {addingNewProfile && (
          <>
            <Col xs="4" sm="3" className="d-grid">
              <input
                type="text"
                className="form-control form-control-sm"
                value={newProfileName}
                placeholder="Enter profile name"
                onChange={(e) => setNewProfileName(e.currentTarget.value)}
              />
            </Col>
            <Col xs="3" sm="2" className="d-grid">
              <Button
                size="sm"
                disabled={newProfileName === "" ? true : false}
                onClick={saveNewProfile}
              >
                <i className="bi bi-check" /> Save
              </Button>
            </Col>
            <Col xs="3" sm="2" className="d-grid">
              <Button
                size="sm"
                variant="secondary"
                onClick={() => setAddingNewProfile(false)}
              >
                <i className="bi bi-x" /> Cancel
              </Button>
            </Col>
          </>
        )}
        {!addingNewProfile && (
          <>
            <Col xs="4" sm="3" className="d-grid">
              <FormSelect
                size="sm"
                value={selectedProfile.id}
                onChange={(e) => onProfileChange(e.currentTarget.value)}
              >
                {ratesState.profiles.map((profile, index) => (
                  <option key={index} value={profile.id}>
                    {profile.name}
                  </option>
                ))}
              </FormSelect>
            </Col>
            <Col xs="3" sm="2" className="d-grid">
              <Button size="sm" onClick={() => setAddingNewProfile(true)}>
                <i className="bi bi-plus" /> New
              </Button>
            </Col>
            <Col xs="3" sm="2">
              <Button
                size="sm"
                variant="danger"
                onClick={() => ratesState.removeRateProfile(selectedProfile)}
              >
                <i className="bi bi-trash" />
              </Button>
            </Col>
          </>
        )}
      </Row>
      {addingNewProfile && (
        <Row className="mb-2">
          <label className="col-3 col-sm-2">Copy from: </label>
          <Col xs="4" sm="3" className="d-grid">
            <FormSelect
              size="sm"
              value={copyFromId}
              onChange={(e) => setCopyFromId(e.currentTarget.value)}
            >
              <option>Choose rate profile</option>
              <optgroup label="Default profiles">
                {suggestedRates.map((rate) => (
                  <option key={rate.id} value={rate.id}>
                    {rate.name}
                  </option>
                ))}
              </optgroup>
              <optgroup label="User profiles">
                {userProfiles.map((rate) => (
                  <option key={rate.id} value={rate.id}>
                    {rate.name}
                  </option>
                ))}
              </optgroup>
            </FormSelect>
          </Col>
        </Row>
      )}
      {!addingNewProfile && (
        <div className="row">
          <label className="col-3 col-sm-2">Rate type</label>
          <Col xs="4" sm="3" className="d-grid">
            <FormSelect
              size="sm"
              value={selectedProfile?.type || undefined}
              onChange={(e) =>
                updateProfileType(e.currentTarget.value as RateType)
              }
            >
              {rateTypes.map((rateType) => (
                <option key={rateType.key} value={rateType.key}>
                  {rateType.name}
                </option>
              ))}
            </FormSelect>
          </Col>
        </div>
      )}
      <div className="row mt-2">
        <Col xs="12" sm="6">
          <Card>
            <Card.Header>Rates</Card.Header>
            <Card.Body>
              <table>
                <tbody>
                  <tr>
                    <th></th>
                    {selectedRateType?.props.map((rateType, index) => (
                      <th className="text-center max-col" key={index}>
                        {rateType.name}
                      </th>
                    ))}
                    <th style={{ width: "50px" }}> deg/s</th>
                  </tr>
                  {axes.map((ax, axIndex) => (
                    <tr key={axIndex}>
                      <td>{ax.toLocaleUpperCase()}</td>
                      {selectedRateType?.props.map((rateType, index) => (
                        <td key={index}>
                          <FormControl
                            className="prop-input"
                            type="number"
                            disabled={addingNewProfile}
                            step={rateType.step || 0.01}
                            min={rateType.min || 0}
                            max={rateType.max || undefined}
                            onChange={(e) => {
                              let value = Number(e.currentTarget.value);
                              if (value < (rateType?.min ?? 0))
                                value = rateType?.min ?? 0;
                              if (value > (rateType?.max ?? 0))
                                value = rateType?.max ?? 0;

                              updateRateValue(ax, rateType.key, value);
                            }}
                            value={
                              selectedProfile.values[ax][rateType.key] || 0
                            }
                          />
                        </td>
                      ))}
                      <td>
                        {Math.round(
                          inputToDegres(
                            selectedProfile.type,
                            1,
                            selectedProfile.values[ax]
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" sm="6" md="4">
          <Card>
            <Card.Header>Rates</Card.Header>
            <Card.Body>
              <canvas
                ref={chartRef}
                style={{ width: "100%", height: "140px" }}
              ></canvas>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
};
