import { EventEmitter } from "@foxify/events";
import { storage } from "./Storage";

export type UserStateEvents = {
    "settings:changed": (key: string) => void,
}

export type CameraFovType = "horizontal" | "vertical" | "diagonal";
export class GameSettingsState extends EventEmitter<UserStateEvents> {

    get cameraAngle(): number {
        return storage.getNum("camera_angle", 10) as number;
    }
    set cameraAngle(value: number) {
        storage.setNum("camera_angle", value);
        this.emit("settings:changed", "camera_angle");
    }
    get cameraFov(): number | undefined {
        return storage.getNum("camera_fov", 155);
    }
    set cameraFov(value: number | undefined) {
        if (value === undefined) {
            storage.remove("camera_fov");
        } else {
            storage.setNum("camera_fov", value);
        }
        this.emit("settings:changed", "camera_fov");
    }
    get cameraFovType(): CameraFovType {
        return storage.getAny("camera_fov_type", "diagonal");
    }
    set cameraFovType(value: CameraFovType) {
        storage.setAny("camera_fov_type", value);
        this.emit("settings:changed", "camera_fov_type");
    }

    get cameraMixAngle(): number {
        return storage.getAny("camera_mix_angle", 0);
    }
    set cameraMixAngle(value: number) {
        storage.setNum("camera_mix_angle", value);
        this.emit("settings:changed", "camera_mix_angle");
    }
    get screenAspectRation(): number {
        return storage.getAny("screen_aspect_ratio", 0);
    }
    set screenAspectRation(value: number) {
        storage.setNum("screen_aspect_ratio", value);
        this.emit("settings:changed", "screen_aspect_ratio");
    }

}

export const gameSettings = new GameSettingsState();