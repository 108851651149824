import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { drones } from "../../engine/data/drones";
import { tutorials } from "../../engine/data/maps/tutorial";
import { soundEngine } from "../../engine/logic/sound/SoundEngine";
import { gameState } from "../../state/GameState";
import "./PlayTrack.css";

export const PlayTrack = () => {
  const { trackId } = useParams();
  const navigate = useNavigate();
  const [menu, setMenu] = useState<"camera" | "config" | "controller">();

  const init = async () => {
    const track = tutorials.find((track) => track.id === trackId);
    if (track) {
      // await userState.loadUserSettings();

      gameState.emit("track:init", track);
      gameState.emit("drone:init", drones[0]);
    }
  };
  useEffect(() => {
    gameState.engineStart("game");
    // soundEngine.start()
    return () => {
      gameState.engineStop();
      soundEngine.stop();
      console.log("engine down");
    };
  }, []);

  useEffect(() => {
    if (trackId) {
      console.log("init track ui");
      init();
    }
  }, [trackId]);
  const showButtons = menu === undefined;
  return (
    <>
      {showButtons && (
        <>
          <button
            className="game-button back"
            onClick={() => navigate("/play")}
          >
            {" "}
            <i className="bi bi-arrow-left" />
          </button>
          <button
            className="game-button full-screen"
            onClick={() => gameState.emit("ui:fullscreen:toggle")}
          >
            {" "}
            <i className="bi bi-arrows-fullscreen" />
          </button>
          <button
            className="game-button config"
            onClick={() => setMenu("config")}
          >
            {" "}
            <i className="bi bi-sliders" />
          </button>
          {/* <button
                        className="game-button path"
                        onClick={() => setMenu('controller')}
                    >
                        {' '}
                        <i className="bi bi-bezier" />
                    </button> */}
          <button
            className="game-button play"
            onClick={() => gameState.emit("start")}
          >
            {" "}
            <i className="bi bi-play" />
          </button>
          {/* <UserButton /> */}
        </>
      )}
      {menu === "config" && (
        <div className="in-game-config">
          <button
            className="game-button close-button"
            onClick={() => setMenu(undefined)}
          >
            {" "}
            <i className="bi bi-x-lg" />
            <i className="bi bi-x" />
          </button>
        </div>
      )}
      {/* <button className="game-button"> <i className="bi bi-list-ol"/></button> */}
    </>
  );
};
