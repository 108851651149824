export function openFullscreen() {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if ((elem as any).mozRequestFullScreen) { /* Firefox */
    (elem as any).mozRequestFullScreen();
  } else if ((elem as any).webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    (elem as any).webkitRequestFullscreen();
  } else if ((elem as any).msRequestFullscreen) { /* IE/Edge */
    (elem as any).msRequestFullscreen();
  }
}

// Close fullscreen
export function closeFullscreen() {
  console.log("closeFullscreen")
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document as any).mozCancelFullScreen) { /* Firefox */
    (document as any).mozCancelFullScreen();
  } else if ((document as any).webkitExitFullscreen) { /* Chrome, Safari and Opera */
    (document as any).webkitExitFullscreen();
  } else if ((document as any).msExitFullscreen) { /* IE/Edge */
    (document as any).msExitFullscreen();
  }
}