import "@babylonjs/loaders/glTF";

import "./api/axios.config";
import { initShaders } from "./engine/scene/shaders";
import { gameState } from "./state/GameState";
import { closeFullscreen, openFullscreen } from "./util/browser";

import { createRoot } from "react-dom/client";
import { auth } from "./entities/auth";
import { AppRoutes } from "./pages/AppRoutes";
import reportWebVitals from "./reportWebVitals";
import "./styles/app.css";
auth.checkAuth();

initShaders();
let fullscreen = false;

gameState.on("ui:fullscreen:toggle", () => {
  if (fullscreen) {
    closeFullscreen();
    fullscreen = false;
  } else {
    openFullscreen();
    fullscreen = true;
  }
});

const uiElement = document.getElementById("app");
if (uiElement) {
  const uiRoot = createRoot(uiElement);
  uiRoot.render(<AppRoutes />);
}
reportWebVitals();
