import { useEffect, useState } from "react";
import "./MenuTiles.scss";

type TileItemDefault = {
  key: string;
  title: string;
  onClick?: () => void;
};
export type TileItem = TileItemDefault & {
  type: "tile";
  content: React.ReactNode | (() => React.ReactNode) | JSX.Element;
};
export type TileImageItem = TileItemDefault & {
  type: "image";
  image: string;
};
export type TileItemTypes = TileItem | TileImageItem;

export type MenuTilesProps = {
  tiles: TileItemTypes[];
  focused: boolean;
  onUp?: () => void;
  onDown?: () => void;
  onTileClick?: (tile: TileItemTypes) => void;
  visible: boolean;
};

export const MenuTiles = ({
  tiles,
  focused,
  onDown,
  onUp,
  onTileClick,
  visible,
}: MenuTilesProps) => {
  const [selectedTile, setSelectedTile] = useState(tiles?.[0]?.key ?? "");
  useEffect(() => {
    // Need to add navigation wia arrow keys
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!focused) return;
      // let tabIndex = tabs.findIndex((tab) => tab.key === selectedTab)
      if (e.key === "ArrowRight") {
        // newTabKey = tabs[(tabIndex + 1) % tabs.length].key
      }
      if (e.key === "ArrowLeft") {
        // newTabKey = tabs[(tabIndex - 1 + tabs.length) % tabs.length].key
      }
      // if (newTabKey !== selectedTab) {
      //     setSelectedTab(newTabKey)
      //     if (onTabChange) onTabChange(newTabKey)
      // }
      if (e.key === "ArrowDown" && onDown) onDown();
      if (e.key === "ArrowUp" && onUp) onUp();
    };
    window.addEventListener("keyup", handleKeyPress);
    return () => window.removeEventListener("keyup", handleKeyPress);
  }, [selectedTile, focused]);

  return (
    <div
      className={`menu-tiles ${focused ? "focused" : ""} ${
        visible ? "" : "hidden"
      }`}
    >
      {tiles.map((tile) => (
        <div
          key={tile.key}
          className={`menu-tile ${selectedTile === tile.key ? "selected" : ""}`}
          onClick={() => {
            setSelectedTile(tile.key);
            if (onTileClick) onTileClick(tile);
            if (tile.onClick) tile.onClick();
          }}
        >
          {tile.type === "tile" ? (
            <div className="tile-content">
              {typeof tile.content === "function"
                ? tile.content()
                : tile.content}
            </div>
          ) : (
            <img src={tile.image} alt={tile.title} />
          )}
          <div className="tile-title">{tile.title}</div>
        </div>
      ))}
    </div>
  );
};
