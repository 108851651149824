import { EventState, Matrix, Quaternion, Scene, Vector3 } from "@babylonjs/core";
import { gameSettings } from "../../state/GameSettings";
import { ratesState } from "../../state/RatesState";
import { ControllerOutput } from "../../types";
import { Constants } from "../../types/Constants";
import { convertRange } from "../../util/range";
import { Controller } from "../gamepad/Controller";
import { Drone } from "../logic/Drone";
import { IPlugin } from "./IPlugin";
import { DebugViewer } from "./simulator/DebugViewer";


const DEG_TO_RAD = Math.PI / 180;
const RAD_TO_DEG = 180 / Math.PI;

export class SimulatorPlugin implements IPlugin {
    private controler: Controller;
    private debugViewer: DebugViewer;
    constructor(private scene: Scene) {
        this.controler = new Controller();
        this.debugViewer = new DebugViewer(scene);

    }
    load(): void {
        this.scene.onBeforePhysicsObservable.add(this.beforeRender.bind(this));
        this.debugViewer.addDebugItem("throttle", "Throttle", (value) => `${value.toFixed(2)} %`);
        this.debugViewer.addDebugItem("upforce", "Upforce", (value) => `${value.toFixed(2)} kg`);
        this.debugViewer.setDebug(true);
    }
    dispose(): void {
        this.scene.onBeforePhysicsObservable.removeCallback(this.beforeRender.bind(this));
        this.debugViewer.dispose();
    }

    beforeRender(scene: Scene, eventState: EventState) {
        let mode = "acro";

        const drone = scene.getTransformNodeByName("player") as Drone;
        if (!this.controler.connected) return;

        if (drone) {
            const userInputs = this.controler.getTRYPValues();
            if (!userInputs) return;

            const mesh = drone.mesh;

            let newAngularVelocity = new Vector3(0, 0, 0);

            if (mode === "acro") {
                newAngularVelocity = this.calculateAcro(drone, userInputs);
            } else if (mode === "angle") {
                newAngularVelocity = this.calculateAngle(drone, userInputs);
            } else if (mode === "horizon") {
                newAngularVelocity = this.calculateHorizon(drone, userInputs);
            } else if (mode === "3d") {
                newAngularVelocity = this.calculateAcro(drone, userInputs);
            }

            let rotationMatrix = new Matrix();
            drone.mesh.rotationQuaternion?.toRotationMatrix(rotationMatrix);
            let worldAngularVelocity = Vector3.TransformCoordinates(newAngularVelocity, rotationMatrix);


            const batteryVoltage = drone.battery.getVoltage();


            drone.mesh.physicsImpostor?.setAngularVelocity(worldAngularVelocity);

            // Let's calculate new angular velocity
            // TODO: we should compare diff between current and new angular velocity and apply it to the drone
            // And then we should calculate PID controller for each axis
            // And then to calculate new angular velocity we should apply PID controller output to the drone

            let throttle = convertRange(userInputs.throttle, -1, 1, 0, 1);
            if (mode === "angle") {
                throttle = convertRange(userInputs.throttle < 0 ? 0 : userInputs.throttle, 0, 1, 0, 1);
            } else if (mode === "3d") {
                throttle = Math.abs(userInputs.throttle);
            }


            const motorsCharacteristics = drone.motorInterpolator.getMotorCharacteristics(throttle,
                batteryVoltage);
            const thrust = motorsCharacteristics.thrust * 3.2 / 1000;

            // This is calucated from PID controller
            const thrustForSound = motorsCharacteristics.thrust / 1000;
            const rpm = motorsCharacteristics.rpm;
            // soundEngine.setRPMs(rpm, rpm, rpm, rpm);
            // const amperage = motorsCharacteristics.reduce((acc, curr) => acc + curr.amps, 0);
            // scene.get
            // console.log(thrust);
            let thrustInNewton = thrust * Constants.gravity;

            // calculating air resistance
            const dragCoefficient = 0.3;
            const area = 0.12; // surface area in square meters
            const airDensity = 1.225; // air density in kg/m^3 at sea level
            const velocity = drone.getVelocity();
            if (!velocity) return;
            const airResistanceMagnitude = 0.5 * dragCoefficient * area * airDensity * velocity.lengthSquared();
            const airResistanceDirection = velocity.normalize().scale(-1);
            const airResistanceForce = airResistanceDirection.scale(airResistanceMagnitude);
            // console.log(airResistanceForce);

            if (mode === "3d" && userInputs.throttle < 0) {
                thrustInNewton = -thrustInNewton;
            }
            // Let's caclulate the net force            
            const netForce = mesh.up
                .scale(thrustInNewton)
                .add(airResistanceForce);

            this.debugViewer.update({
                throttle: throttle * 100,
                upforce: thrust
            });

            drone.mesh.physicsImpostor?.applyForce(
                netForce,
                mesh.getAbsolutePosition().add(Vector3.Zero())
            );
        }

    }
    calculateAltitudeHold(drone: Drone, inputs: ControllerOutput): Vector3 {
        return this.calculateAngle(drone, inputs);
    }
    calculateAcro(drone: Drone, inputs: ControllerOutput): Vector3 {
        const inputsWithRates = ratesState.inputsToRates(inputs);

        let desiredAngularVelocityX = inputsWithRates.pitch * DEG_TO_RAD;
        let desiredAngularVelocityY = inputsWithRates.yaw * DEG_TO_RAD;
        let desiredAngularVelocityZ = - inputsWithRates.roll * DEG_TO_RAD;

        if (gameSettings.cameraMixAngle !== 0) {
            const effectiveTiltRad = (- gameSettings.cameraMixAngle) * DEG_TO_RAD;
            const cosTilt = Math.cos(effectiveTiltRad);
            const sinTilt = Math.sin(effectiveTiltRad);

            const adjustedYaw = cosTilt * desiredAngularVelocityY - sinTilt * desiredAngularVelocityZ;
            const adjustedRoll = sinTilt * desiredAngularVelocityY + cosTilt * desiredAngularVelocityZ;

            desiredAngularVelocityY = adjustedYaw;
            desiredAngularVelocityZ = adjustedRoll;
        }

        return new Vector3(desiredAngularVelocityX, desiredAngularVelocityY, desiredAngularVelocityZ);
    }
    calculateAngle(drone: Drone, inputs: ControllerOutput): Vector3 {
        let maxTiltAngle = 45;
        const inputsWithRates = ratesState.inputsToRates(inputs);

        // Compute the target angles based on user inputs
        let targetAngleX = maxTiltAngle * inputs.pitch; // in degrees
        let targetAngleZ = -maxTiltAngle * inputs.roll; // in degrees

        // Convert the target angles to radians
        let targetAngleXRad = targetAngleX * Math.PI / 180;
        let targetAngleZRad = targetAngleZ * Math.PI / 180;

        // Create a target rotation quaternion from the target angles
        let targetRotationQuaternion = Quaternion.RotationYawPitchRoll(0, targetAngleXRad, targetAngleZRad);

        // Get the current rotation quaternion of the drone mesh
        let currentRotationQuaternion = drone.mesh.rotationQuaternion;

        // Convert both quaternions to Euler angles
        let targetEuler = targetRotationQuaternion.toEulerAngles();
        let currentEuler = currentRotationQuaternion!.toEulerAngles();

        // Compute the angular velocity using a P controller
        let kP = 1.0; // Proportional gain. Adjust this value as needed.
        let angularVelocityX = kP * (targetEuler.x - currentEuler.x);
        let angularVelocityZ = kP * (targetEuler.z - currentEuler.z);

        return new Vector3(angularVelocityX, inputsWithRates.yaw * DEG_TO_RAD, angularVelocityZ);
    }

    calculateHorizon(drone: Drone, inputs: ControllerOutput) {
        if (Math.abs(inputs.pitch) < 0.5 && Math.abs(inputs.roll) < 0.5) {
            return this.calculateAngle(drone, inputs);
        } else {
            return this.calculateAcro(drone, inputs);
        }
    }
}