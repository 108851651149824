import { Google } from "react-bootstrap-icons";
import { Navigate, useNavigate, useParams } from "react-router";
import { MenuLayout } from "../../components/PageLayout";
import { auth } from "../../entities/auth";
import { app } from "../../util/app";
import "./AuthPage.css";
export const AuthPage = () => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const sigInWithCordova = async (provider: any) => {};
  const signInWithGoogle = async () => {
    // navigate('/auth/google')
    document.location.href = process.env.REACT_APP_GOOGLE_AUTH_URL ?? "";
  };

  if (auth.user) {
    return <Navigate to="/" replace />;
  }
  return (
    <MenuLayout hideMenu>
      <div className="auth-page">
        <div className="auth-page__content">
          {app.isCordova() && (
            <div className="auth-page__content__item">
              <button
                className="auth-button button-apple"
                onClick={sigInWithCordova}
              >
                Sign in
              </button>
            </div>
          )}
          {app.isCordova() == false && (
            <>
              <div className="auth-title">Sign in:</div>
              <div className="auth-page__content__item">
                <button
                  className="auth-button button-google"
                  onClick={signInWithGoogle}
                >
                  <Google /> With Google
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </MenuLayout>
  );
};
