import {
  MeshBuilder,
  Scene,
  Vector3
} from "@babylonjs/core";
import { Obstacle } from "../Obstacle";
import { getGatesSizes } from "./GatesConstants";
import { MGPGate } from "./MGPGate";

export class MGPTower extends Obstacle {
  constructor(name: string, scene: Scene, isBig: boolean = false) {
    super(name, scene);
    const { towerElevation, depth, width } = getGatesSizes(isBig);

    const gate = new MGPGate("gate", scene, isBig, false, true);
    gate.position = new Vector3(0, towerElevation, 0);
    gate.setParent(this);

    const leftPipe = MeshBuilder.CreateBox("leftPipe", { width: depth, height: towerElevation, depth, updatable: false }, scene);
    const rightPipe = MeshBuilder.CreateBox("rightPipe", { width: depth, height: towerElevation, depth, updatable: false }, scene);

    leftPipe.position = new Vector3(-width / 2 + depth / 2, towerElevation / 2, 0);
    rightPipe.position = new Vector3(width / 2 - depth / 2, towerElevation / 2, 0);

    leftPipe.setParent(this);
    rightPipe.setParent(this);


    this.coliders.push(...gate.coliders);
    this.coliders.push(leftPipe);
    this.coliders.push(rightPipe);

    this.checkpoints.push(gate.checkpoints[0]);
  }
}
