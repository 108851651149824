import { MenuLayout } from '../components/PageLayout'
export const AboutPage = () => {
    
    return (
        <MenuLayout showBackButton={true}>
            <div style={{ color: 'black' }}>
                <h1>About</h1>
                <p>
                    Drone Racing is a game where you fly a drone through a
                    track.
                </p>
                <p>It is a work in progress.</p>
            </div>
        </MenuLayout>
    )
}
