import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MenuTabs.scss";

export type TabItem = {
  key: string;
  title: string;
  badge?: number;
  icon?: React.ReactNode;
  url: string;
};

export type MenuTabsProps = {
  tabs: TabItem[];
  focused: boolean;
  onUp?: () => void;
  onDown?: () => void;
  onTabChange?: (tab: string) => void;
};
const tabs = ["home", "play", "friends", "profile", "settings"];

type MenuTabProps = TabItem & {
  selected: boolean;
  onClick: () => void;
};

const MenuTab = ({ title, selected, onClick, badge, icon }: MenuTabProps) => {
  return (
    <div className={`menu-tab ${selected ? "selected" : ""}`} onClick={onClick}>
      {icon && (
        <div
          style={{
            display: "inline-block",
          }}
        >
          {icon}
        </div>
      )}
      {badge && <div className="tab-badge">{badge}</div>}
      <div>{title}</div>
    </div>
  );
};

export const MenuTabs = ({
  tabs,
  focused,
  onDown,
  onUp,
  onTabChange,
}: MenuTabsProps) => {
  const path = window.location.pathname;
  const [selectedTab, setSelectedTab] = useState(
    tabs.find((tab) => tab.url === path)?.key || ""
  );
  const navigate = useNavigate();
  useEffect(() => {
    // Need to add navigation wia arrow keys
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!focused) return;
      let newTabKey = selectedTab;
      let tabIndex = tabs.findIndex((tab) => tab.key === selectedTab);
      if (e.key === "ArrowRight") {
        newTabKey = tabs[(tabIndex + 1) % tabs.length].key;
      }
      if (e.key === "ArrowLeft") {
        newTabKey = tabs[(tabIndex - 1 + tabs.length) % tabs.length].key;
      }
      if (newTabKey !== selectedTab) {
        setSelectedTab(newTabKey);
        if (onTabChange) onTabChange(newTabKey);
        console.log(newTabKey);
      }
      if (e.key === "ArrowDown" && onDown) onDown();
      if (e.key === "ArrowUp" && onUp) onUp();
    };
    window.addEventListener("keyup", handleKeyPress);
    return () => window.removeEventListener("keyup", handleKeyPress);
  }, [selectedTab, focused]);

  useEffect(() => {
    const tabItem = tabs.find((tab) => tab.key === selectedTab);
    if (tabItem) {
      navigate(tabItem.url, { replace: false });
    }
  }, [selectedTab]);
  return (
    <div className={`menu-tabs ${focused ? "focused" : ""}`}>
      {tabs.map((tab) => (
        <MenuTab
          key={tab.key}
          title={tab.title}
          selected={selectedTab === tab.key}
          icon={tab.icon}
          url={tab.url}
          onClick={() => {
            setSelectedTab(tab.key);
            if (onTabChange) onTabChange(tab.key);
          }}
        />
      ))}
    </div>
  );
};
