import {
  MeshBuilder,
  Scene,
  Vector3
} from "@babylonjs/core";
import { materialUtil } from "../../MaterialUtil";
import { Checkpoint } from "../Checkpoint";
import { Obstacle } from "../Obstacle";
import { GatesConstants, getGatesSizes } from "./GatesConstants";

const mgpGateName = (isbig: boolean) => `MGPGate${isbig ? '7x6' : '5x5'}`;

export class MGPGate extends Obstacle {
  constructor(name: string, scene: Scene, isBig: boolean = false, hideTop: boolean = false, showBottom: boolean = false) {
    super(mgpGateName(isBig), scene);

    const sizes = getGatesSizes(isBig);
    const heightOffset = showBottom ? sizes.topPanelSize : 0;
    const depth = GatesConstants.DEPTH;
    const leftMesh = MeshBuilder.CreateBox("left", { width: sizes.sidePanelSize, height: sizes.sideHeight, depth, updatable: false }, scene);
    const rightMesh = MeshBuilder.CreateBox("right", { width: sizes.sidePanelSize, height: sizes.sideHeight, depth, updatable: false }, scene);

    const checkpoint = new Checkpoint(`${this.name}: Checkpoint`, { shape: "square", width: sizes.innerWidth, height: sizes.sideHeight, depth }, scene);
    leftMesh.position = new Vector3(-sizes.width / 2 + sizes.sidePanelSize / 2, sizes.sideHeight / 2 + heightOffset, 0);
    rightMesh.position = new Vector3(sizes.width / 2 - sizes.sidePanelSize / 2, sizes.sideHeight / 2 + heightOffset, 0);
    checkpoint.position.y = heightOffset;
    leftMesh.setParent(this);
    rightMesh.setParent(this);
    checkpoint.setParent(this);

    this.coliders = [leftMesh, rightMesh];
    this.checkpoints.push(checkpoint)


    materialUtil.applyVerticalMaterial(leftMesh, scene);
    materialUtil.applyVerticalMaterial(rightMesh, scene);

    if (!hideTop) {
      const topMesh = MeshBuilder.CreateBox("top", { width: sizes.width, height: sizes.topPanelSize, depth }, scene);
      topMesh.setParent(this);
      topMesh.position.y = sizes.height - sizes.topPanelSize / 2 + heightOffset;
      this.coliders.push(topMesh);
      materialUtil.applyHorizontalMaterial(topMesh, scene);

    }
    if (showBottom) {
      const bottomMesh = MeshBuilder.CreateBox("bottom", { width: sizes.width, height: sizes.topPanelSize, depth }, scene);
      bottomMesh.setParent(this);
      bottomMesh.position.y = sizes.topPanelSize / 2;
      this.coliders.push(bottomMesh);
      materialUtil.applyHorizontalMaterial(bottomMesh, scene);
    }
  }
}
