import { useEffect, useState } from 'react'
import { ArrowDown, ArrowUp, Pencil, Trash } from 'react-bootstrap-icons'
import { trackEditorState } from '../../state/TrackEditorState'
import './Checkpoints.scss'
import { NodeDetails } from './NodeDetails'
import { GameNode } from '../../engine/scene/GameNode'
import { getObstacleTitle } from '../../engine/scene/obstacles/ObstacleFactory'
export const Checkpoints = () => {
    const [isExpanded, setIsExpanded] = useState(true)
    const [obstacles, setObstacles] = useState<GameNode[]>([])
    const [selectedObstacle, setSelectedObstacle] = useState<GameNode | null>(
        null
    )
    useEffect(() => {
        trackEditorState.on('track:changed', () => {
            setObstacles([...trackEditorState.getObstacles()])
        })
        trackEditorState.on('node:selected', setSelectedObstacle)
        trackEditorState.on('node:unselected', () => setSelectedObstacle(null))
    }, [])
    return (
        <div className="obstacles">
            {obstacles.map((obstacle) => {
                return (
                    <div className="obstacle" key={obstacle.id}>
                        <div className="obstacle-name">
                            {getObstacleTitle(obstacle.name)}
                        </div>
                        <div className="obstacle-buttons">
                            <button
                                className="transparent-button"
                                onClick={() => {
                                    trackEditorState.emit(
                                        'node:selected',
                                        obstacle
                                    )
                                    trackEditorState.emit(
                                        'ui:node:focus',
                                        obstacle.id
                                    )
                                }}
                            >
                                <Pencil />
                            </button>
                            <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() =>
                                    trackEditorState.emit(
                                        'ui:node:remove',
                                        obstacle.id
                                    )
                                }
                            >
                                <Trash />
                            </button>
                            <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() =>
                                    trackEditorState.moveUp(obstacle.id)
                                }
                            >
                                <ArrowUp />
                            </button>
                            <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() =>
                                    trackEditorState.moveDown(obstacle.id)
                                }
                            >
                                <ArrowDown />
                            </button>
                        </div>
                        {selectedObstacle &&
                            selectedObstacle.id === obstacle.id && (
                                <NodeDetails node={obstacle} />
                            )}
                    </div>
                )
            })}
        </div>
    )
}
