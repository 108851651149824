import { Scene } from "@babylonjs/core";

export declare class IPlugin {

    load(): void
    dispose(): void

}
export interface IPluginConstructor {
    new(scene: Scene): IPlugin;
}

export function createPlugin(constructor: IPluginConstructor, scene: Scene): IPlugin {
    return new constructor(scene);
}