import {
  Scene,
  Vector3
} from "@babylonjs/core";
import { Checkpoint } from "../Checkpoint";
import { Obstacle } from "../Obstacle";
import { getGatesSizes } from "./GatesConstants";
import { MGPGate } from "./MGPGate";

const mgpLadderName = (isbig: boolean, hideTop: boolean, size: number) => {
  return `MGP${hideTop ? 'Topless' : ''}Ladder${size}x${isbig ? '7x6' : '5x5'}`;
};
export class MGPLadder extends Obstacle {
  constructor(name: string, scene: Scene, isBig: boolean = false, hideTop: boolean = false, size: number = 3) {
    super(mgpLadderName(isBig, hideTop, size), scene);
    const { height, innerWidth, sideHeight, depth, sidePanelSize } = getGatesSizes(isBig);
    for (let i = 0; i < size; i++) {
      const topless = i === size - 1 && hideTop;
      const gate = new MGPGate("gate" + i, scene, isBig, topless);
      gate.position = new Vector3(0, height * i, 0);
      gate.setParent(this);

      this.coliders.push(...gate.coliders);
      this.checkpoints.push(...gate.checkpoints);
      if (i < size - 1) {
        const helperCheckpoint = new Checkpoint("helper" + i, { shape: "square", depth, height: sideHeight, width: innerWidth, isHelper: true }, scene);
        helperCheckpoint.position = new Vector3(innerWidth + sidePanelSize, height * i + height / 2, 0);
        helperCheckpoint.rotation.y = Math.PI;
        helperCheckpoint.setParent(this);
      }

    }
  }
}
