import { Color3, HemisphericLight, MeshBuilder, PhysicsImpostor, Vector3 } from "@babylonjs/core";
import { GridMaterial } from "@babylonjs/materials";
import { AbstractEnviroment } from "./AbstractEnviroment";

export class EmptyEnviroment extends AbstractEnviroment {
    get name() {
        return "empty";
    }
    init() {
        var light = new HemisphericLight("global_light", new Vector3(0, 1, 0), this.scene);
        light.specular = new Color3(0, 0, 0);// turn of spec highlights

        var groundMaterial = new GridMaterial("ground", this.scene);

        groundMaterial.majorUnitFrequency = 5;
        groundMaterial.minorUnitVisibility = 0.45;
        groundMaterial.gridRatio = .9;
        groundMaterial.backFaceCulling = false;
        groundMaterial.mainColor = new Color3(1, 1, 1);
        groundMaterial.lineColor = new Color3(.3, .4, .47);
        groundMaterial.opacity = 0.98;

        const { width, height } = this.calculateGroundSize();
        const ground = MeshBuilder.CreateGround("ground", { height, width, updatable: false }, this.scene);


        ground.material = groundMaterial;
        ground.physicsImpostor = new PhysicsImpostor(
            ground,
            PhysicsImpostor.PlaneImpostor,
            { mass: 0, friction: 0.4, restitution: 0.7, damping: 100 },
            this.scene
        );
        ground.checkCollisions = true;
    }
}