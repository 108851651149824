import {
  MeshBuilder,
  Scene,
  Vector3
} from "@babylonjs/core";
import { materialUtil } from "../../MaterialUtil";
import { Obstacle } from "../Obstacle";
import { getGatesSizes } from "./GatesConstants";
import { MGPGate } from "./MGPGate";

export class MGPGateFlag extends Obstacle {
  constructor(name: string, scene: Scene, isBig: boolean = false) {
    super(`MPGGateFlag${isBig ? '7x6' : '5x5'}`, scene);
    const { sideHeight, depth, sidePanelSize, height, width } = getGatesSizes(isBig);

    const gate = new MGPGate("gate", scene, isBig);
    gate.setParent(this);
    const flag = MeshBuilder.CreateBox("flag", { width: sidePanelSize, height: sideHeight, depth, updatable: false }, scene);

    flag.position = new Vector3(width / 2 - sidePanelSize / 2, height + sideHeight / 2, 0);
    flag.setParent(this);
    materialUtil.applyVerticalMaterial(flag, scene);
    this.checkpoints.push(...gate.checkpoints);

    this.coliders.push(...gate.coliders);
    this.coliders.push(flag);
  }
}
