import { AbstractMesh, Curve3, EventState, LinesMesh, MeshBuilder, Scene, Vector3 } from "@babylonjs/core";
import { gameState } from "../../state/GameState";
import { trackEditorState } from "../../state/TrackEditorState";
import { Checkpoint } from "../scene/obstacles/Checkpoint";
import { IPlugin } from "./IPlugin";

export class CheckpointsPathPlugin implements IPlugin {
    private checkpoints: Checkpoint[] = [];
    private lines?: LinesMesh;
    private isLoaded: boolean = false;
    constructor(private scene: Scene) {
    }

    load(): void {
        this.scene.onNewMeshAddedObservable.add(this.onMeshAdded.bind(this));
        this.scene.onDataLoadedObservable.add(this.onDataLoaded.bind(this));
        trackEditorState.on("track:changed", this.buildPath.bind(this));
        gameState.on("scene:loaded", this.buildPath.bind(this));
    }
    dispose(): void {
        this.scene.onNewMeshAddedObservable.removeCallback(this.onMeshAdded.bind(this));
        this.scene.onDataLoadedObservable.removeCallback(this.onDataLoaded.bind(this));
        trackEditorState.off("track:changed", this.buildPath.bind(this));
        gameState.off("scene:loaded", this.buildPath.bind(this));
        if (this.lines) {
            this.lines.dispose();
        }
    }
    onMeshAdded(mesh: AbstractMesh): void {
        this.checkChekpointsChanged();

    }
    onDataLoaded(scene: Scene, event: EventState): void {
        this.isLoaded = true;
        setTimeout(() => {
            this.buildPath();
        }, 1000);
    }
    checkChekpointsChanged(): void {
        if (!this.scene) return;
        const newCheckpoints = this.scene.transformNodes.filter((mesh) => mesh.name.startsWith("Chekpoint:"));
        if (newCheckpoints.length !== this.checkpoints.length) {
            this.checkpoints = newCheckpoints.map((mesh) => mesh as Checkpoint);
            this.buildPath();
        }
    }
    buildPath(): void {
        if (!this.scene || !this.isLoaded) return;
        if (this.lines) {
            this.lines.dispose();
        }
        const checkpoints = this.scene.transformNodes.filter((mesh) => mesh.name.startsWith("Chekpoint:")).map((mesh) => mesh as Checkpoint);
        const curvePoints: Vector3[] = [];
        if (checkpoints.length < 2) return;


        for (let i = 0; i < checkpoints.length - 1; i += 1) {
            curvePoints.push(...this.curvePoints(checkpoints[i], checkpoints[i + 1]));
        }
        curvePoints.push(...this.curvePoints(checkpoints[checkpoints.length - 1], checkpoints[0]));


        this.lines = MeshBuilder.CreateLines("CheckpointsPath", { points: curvePoints }, this.scene);

    }
    curvePoints(checkpoint1: Checkpoint, checkpoint2: Checkpoint) {
        let start = checkpoint1.getWorldCenter();
        let end = checkpoint2.getWorldCenter();
        let distance = Vector3.Distance(start, end);

        distance = distance * 0.3;
        if (distance < 1) distance = 1;
        if (distance > 10) distance = 10;

        let p1 = checkpoint1.getPointAfter(distance);
        let p2 = checkpoint2.getPointBefore(distance);
        let curve = Curve3.CreateCubicBezier(start, p1, p2, end, 50);
        return curve.getPoints();
    }

}
