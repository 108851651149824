import { useState } from 'react'
import { ValueBar } from './ValueBar'

type AxisChooserProps = {
    index: number
    value: number
    axes: { [key: number]: number }
    onChange: (axis: number) => void
}

export const AxisChooser = ({
    index,
    value,
    axes,
    onChange,
}: AxisChooserProps) => {
    const [dropdownVisible, setDropdownVisible] = useState(false)
    return (
        <div className="axis-chooser">
            <div
                className="axis-chooser-button"
                onClick={() => setDropdownVisible(!dropdownVisible)}
            >
                <ValueBar value={value} />
                {index !== undefined ? `CH: ${index}` : 'Select'}
            </div>
            {dropdownVisible && (
                <div className="axis-chooser-dropdown">
                    {Object.keys(axes).map((index) => (
                        <div
                            key={index}
                            className="axis-chooser-item"
                            onClick={() => {
                                onChange(index as any)
                                setDropdownVisible(false)
                            }}
                        >
                            <ValueBar value={axes[Number(index)]} />
                            CH: {index}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
