import { EventEmitter } from "@foxify/events";

type StorageEvents = {
    change: (key: string, value?: string)=> void
};

export class Storage  extends EventEmitter<StorageEvents> {
    private _data: {[key: string]: any} = {};
    constructor() {
        super();
        const data = localStorage.getItem("rotorblitz");
        if(data){
            this._data = JSON.parse(data);            
        }
    }
    saveState() {
        localStorage.setItem("rotorblitz", JSON.stringify(this._data));
    }
    removeItem(key: string) {
        delete this._data[key];
        this.emit("change", key, undefined);
        this.saveState();
    }
    setAny(key: string, value: any) {
        this._data[key] = value;
        this.emit("change", key, value);
        this.saveState();
    }
    getAny(key: string, def?: any) {
        return this._data[key] || def;
    }

    getData() { 
        return this._data;
    }
    setBool(name: string, value: boolean) {
        return this.setAny(name, value);
    }
    setNum(name: string, value: number) {
        return this.setAny(name, value);
    }
    remove(name: string) {
        return this.removeItem(name);
    }
    setString(name: string, value: string) {
        return this.setAny(name, value);
    }
    getBool(name: string) {
        return this.getAny(name) as boolean;
    }
    getNum(name: string, def?: number){
        return this.getAny(name) as number || def;
    }
    getString(name: string, def?: string){
        return this.getAny(name) as string || def;
    }
}
export const storage = new Storage();