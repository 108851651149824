import { MeshBuilder, Scene, StandardMaterial, Texture } from "@babylonjs/core";
import { config } from "../../config";
import { gameState } from "../../state/GameState";
import { getGroundBounds } from "../../util/scene";
import { AssetsLoader } from "./AssetsLoader";
import { IPlugin } from "./IPlugin";
export class AdsLoaderPlugin implements IPlugin {
    private assetsLoader: AssetsLoader;
    constructor(private scene: Scene) {
        this.assetsLoader = new AssetsLoader(scene);


    }

    load() {
        gameState.on("scene:loaded", this.onSceneLoaded.bind(this));
    }
    dispose() {
        gameState.off("scene:loaded", this.onSceneLoaded.bind(this));
    }

    async onSceneLoaded() {
        let { minX, maxX, minZ, maxZ } = getGroundBounds(this.scene);

        if (minX === undefined || maxX === undefined || minZ === undefined || maxZ === undefined) return;
        const wallPadding = 40;
        const wallHeight = 6;
        const wallDepth = 0.1;
        // minX -= wallPadding;
        // maxX += wallPadding;
        // minZ -= wallPadding;
        // maxZ += wallPadding;
        //I need to create a wall of ads around the track

        // const xMinWall = MeshBuilder.CreateBox("xMinWall", { width: wallDepth, height: wallHeight, depth: maxZ - minZ }, this.scene);
        // xMinWall.position.x = minX;
        // xMinWall.position.z = (maxZ + minZ) / 2;
        // xMinWall.position.y = wallHeight / 2;

        // const xMaxWall = MeshBuilder.CreateBox("xMaxWall", { width: wallDepth, height: wallHeight, depth: maxZ - minZ }, this.scene);
        // xMaxWall.position.x = maxX;
        // xMaxWall.position.z = (maxZ + minZ) / 2;
        // xMaxWall.position.y = wallHeight / 2;

        // const zMinWall = MeshBuilder.CreateBox("zMinWall", { width: maxX - minX, height: wallHeight, depth: wallDepth }, this.scene);
        // zMinWall.position.x = (maxX + minX) / 2;
        // zMinWall.position.z = minZ;
        // zMinWall.position.y = wallHeight / 2;

        // const zMaxWall = MeshBuilder.CreateBox("zMaxWall", { width: maxX - minX, height: wallHeight, depth: wallDepth }, this.scene);
        // zMaxWall.position.x = (maxX + minX) / 2;
        // zMaxWall.position.z = maxZ;
        // zMaxWall.position.y = wallHeight / 2;

        // console.log("adss added");
        this.addAds(this.scene, minX, maxX, minZ, maxZ, wallHeight);
        // this.addAdsGridOnWall(this.scene, xMaxWall, "xMaxWall");
        // this.addAdsGridOnWall(this.scene, zMinWall, "zMinWall");
        // this.addAdsGridOnWall(this.scene, zMaxWall, "zMaxWall");

    }
    getAdMaterial(addName: string) {
        const material = new StandardMaterial(`Ad: ${addName}`, this.scene);
        material.diffuseTexture = new Texture(config.assetsUrl + `/amblems/${addName}.png`, this.scene);
        return material;
    }

    addAds(scene: Scene, minX: number, maxX: number, minZ: number, maxZ: number, wallHeight: number) {

        const addMaterials = [
            this.getAdMaterial("rcds"),
            // this.getAdMaterial("archibald"),
            // this.getAdMaterial("gabor"),
            // this.getAdMaterial("koptershop"),
            // this.getAdMaterial("xdroners"),
        ];

        const adsWidth = 5;
        const adsHeight = 5;
        const adsPadding = 0.1;
        const rows = Math.floor(wallHeight / (adsHeight + adsPadding));
        const colsX = Math.floor((maxX - minX) / (adsWidth + adsPadding));
        const colsZ = Math.floor((maxZ - minZ) / (adsWidth + adsPadding));
        let counter = 0;
        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < colsX; j += 1) {
                const plane = MeshBuilder.CreatePlane("plane", { width: adsWidth, height: adsHeight }, scene);
                const plane2 = MeshBuilder.CreatePlane("plane", { width: adsWidth, height: adsHeight }, scene);

                plane.position.y = plane2.position.y = i * (adsHeight + adsPadding) + adsHeight / 2;
                plane.position.x = plane2.position.x = minX + adsWidth / 2 + j * (adsWidth + adsPadding / 2);
                plane.position.z = minZ;
                plane.material = plane2.material = addMaterials[counter % addMaterials.length];
                plane.rotation.y = Math.PI;
                plane2.position.z = maxZ;
                counter++;
            }
            for (let j = 1; j < colsZ; j += 1) {
                const plane = MeshBuilder.CreatePlane("plane", { width: adsWidth, height: adsHeight }, scene);
                const plane2 = MeshBuilder.CreatePlane("plane", { width: adsWidth, height: adsHeight }, scene);

                plane.position.y = plane2.position.y = i * (adsHeight + adsPadding / 2) + adsHeight / 2;
                plane.position.z = plane2.position.z = minZ + adsWidth / 2 + j * (adsWidth + adsPadding / 2);
                plane.position.x = minX;
                plane.material = plane2.material = addMaterials[counter % addMaterials.length];
                plane.rotation.y = - Math.PI / 2;
                plane2.position.x = maxX;
                plane2.rotation.y = Math.PI / 2;
                counter++;
            }
        }
    }

}