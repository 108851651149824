import { RateProfileType, RateType } from "./rates";

export const betaflightDefault: RateProfileType = {
    id: "bf:default", name: "Betaflight default", type: "betaflight", values: {
        roll: { bfRcRate: 1, bfRate: 0.7, bfRcExpo: 0 },
        pitch: { bfRcRate: 1, bfRate: 0.7, bfRcExpo: 0 },
        yaw: { bfRcRate: 1, bfRate: 0.7, bfRcExpo: 0 }
    }
};
export const kissDefaultRate: RateProfileType = {
    id: "kiss:default", name: "KISS default", type: "kiss", values: {
        roll: { kissRcRate: 1, kissRate: 0.7, kissRcCurve: 0 },
        pitch: { kissRcRate: 1, kissRate: 0.7, kissRcCurve: 0 },
        yaw: { kissRcRate: 1, kissRate: 0.7, kissRcCurve: 0 }
    }
};
export const raceflightDefault: RateProfileType = {
    id: "rf:default", name: "Raceflight default", type: "raceflight", values: {
        roll: { rfRate: 370, rfAcro: 80, rfExpo: 50 },
        pitch: { rfRate: 370, rfAcro: 80, rfExpo: 50 },
        yaw: { rfRate: 370, rfAcro: 80, rfExpo: 50 }
    }
};
export const actualDefault: RateProfileType = {
    id: "ac:default", name: "Actual default", type: "actual", values: {
        roll: { acCenterSensitivity: 70, acMaxRate: 670, acExpo: 0 },
        pitch: { acCenterSensitivity: 70, acMaxRate: 670, acExpo: 0 },
        yaw: { acCenterSensitivity: 70, acMaxRate: 670, acExpo: 0 }
    }
};
export const quickratesDefault: RateProfileType = {
    id: "qr:default", name: "QuickRates default", type: "quickrates", values: {
        roll: { qrRcRate: 1, qrMaxRate: 670, qrExpo: 0 },
        pitch: { qrRcRate: 1, qrMaxRate: 670, qrExpo: 0 },
        yaw: { qrRcRate: 1, qrMaxRate: 670, qrExpo: 0 }
    }
};
export const defaultRates: { [ key in RateType ]: RateProfileType} = {
    "betaflight": betaflightDefault,
    "kiss": kissDefaultRate,
    "raceflight": raceflightDefault,
    "actual": actualDefault,
    "quickrates": quickratesDefault
}
// Freestyle RC Rate – 1.3 RC Expo – 0.25 Super Rate – 0.68
// Smooth Cruiser: RC Rate = 0.80 RC Expo = 0.00 Super Rate = 0.65
// Aggressive Acro Quad:
// RC Rate, Pitch/Roll = 1.80, Yaw = 2.00
// Super Rate, All = 0.64
// RC Expo, Pitch/Roll = 0.20, Yaw = 0.15
// Tiny Whoop Rates
// Roll+Pitch: RC Rate 1.2, Super Rate 0.75, Expo 0
// YAW: RC Rate 1.3, Super Rate 0.80 Expo 0


export const suggestedRates: RateProfileType[] = [

    betaflightDefault,
    kissDefaultRate,
    raceflightDefault,
    actualDefault,
    quickratesDefault, { 
    id: "bf:freestyle", name: "Betaflight Freestyle", type: "betaflight", 
    values: {
        roll: { bfRcRate: 1.3, bfRate: 0.68, bfRcExpo: 0.25 },
        pitch: { bfRcRate: 1.3, bfRate: 0.68, bfRcExpo: 0.25 },
        yaw: { bfRcRate: 1.3, bfRate: 0.68, bfRcExpo: 0.25 },
    }
}, {
    id: "bf:smooth", name: "Betaflight Smooth", type: "betaflight",
    values: {
        roll: { bfRcRate: 0.8, bfRate: 0.65, bfRcExpo: 0 },
        pitch: { bfRcRate: 0.8, bfRate: 0.65, bfRcExpo: 0 },
        yaw: { bfRcRate: 0.8, bfRate: 0.65, bfRcExpo: 0 },
    }
}, {
    id: "bf:aggressive", name: "Betaflight Aggressive", type: "betaflight",
    values: {
        roll: { bfRcRate: 1.8, bfRate: 0.64, bfRcExpo: 0.2 },
        pitch: { bfRcRate: 1.8, bfRate: 0.64, bfRcExpo: 0.2 },
        yaw: { bfRcRate: 2, bfRate: 0.64, bfRcExpo: 0.15 },
    }
  },{
    id: "bf:race", name: "Betaflight Race", type: "betaflight",
    values: {
        roll: { bfRcRate: 0.9, bfRate: 0.55, bfRcExpo: 0.15 },
        pitch: { bfRcRate: 0.9, bfRate: 0.55, bfRcExpo: 0.15 },
        yaw: { bfRcRate: 1, bfRate: 0.6, bfRcExpo: 0.1 },
    }
  }, {
    id: "bf:tinywhoop", name: "Betaflight Tiny Whoop", type: "betaflight",
    values: {
        roll: { bfRcRate: 1.2, bfRate: 0.75, bfRcExpo: 0 },
        pitch: { bfRcRate: 1.2, bfRate: 0.75, bfRcExpo: 0 },
        yaw: { bfRcRate: 1.3, bfRate: 0.8, bfRcExpo: 0 },
    } 
}];