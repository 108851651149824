import { Scene } from "@babylonjs/core";
import { findObstacleBounds } from "../../../util/scene";

export abstract class AbstractEnviroment {
    constructor(public scene: Scene) { }
    abstract get name(): string;
    abstract init(): void;

    calculateGroundSize() {
        const { maxX, maxZ, minX, minZ } = findObstacleBounds(this.scene);

        let width = Math.max(Math.abs(maxX), Math.abs(minX)) * 2 + 50;
        let height = Math.max(Math.abs(maxZ), Math.abs(minZ)) * 2 + 50;
        if (width === 50) width = 500;
        if (height === 50) height = 500;
        return { width, height };
    }
}