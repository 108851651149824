import { EventEmitter } from "@foxify/events";
import { storage } from "./Storage";
import { inputToDegres } from "../util/rates";
import { ControllerOutput } from "../types";
import { convertRange } from "../util/range";
import { RateProfileType } from "../types/rates";
import { betaflightDefault, suggestedRates } from "../types/defaultRates";
import * as uuid from "uuid";

export type RatesStateEvents = {
    changed: ()=> void
    "profile:selected": (profile: RateProfileType)=> void
    "profile:added": (profile: RateProfileType)=> void
    "profile:removed": (profile: RateProfileType)=> void
    "profile:updated": (profile: RateProfileType)=> void
    "profiles:changed": (profiles: RateProfileType[])=> void
} 
    

export class RatesState extends EventEmitter<RatesStateEvents> {
    private _profile!: RateProfileType;
    constructor() {
        super();
    }
    selectRateProfile(profile: RateProfileType){
        storage.setAny("selected_rate_profile_id", profile.id);
        this._profile = profile;
        this.emit("profile:selected", profile);
    }
    get profiles(): RateProfileType[] {
        return storage.getAny("rate_profiles", []);
    }
    addRateProfile(profile: RateProfileType) {     
        storage.setAny("rate_profiles", [...this.profiles, profile]);
        this.emit("profile:added", profile);
        this.emit("profiles:changed", this.profiles);
    }
    updateRateProfile(profile: RateProfileType) {
        if(!this.profiles.find(p => p.name === profile.name)) 
            return this.addRateProfile(profile);
        
        const profiles = this.profiles.map(p => {
            if (p.name === profile.name) return profile;
            return p;
        });
        storage.setAny("rate_profiles", profiles);
        this.emit("profile:updated", profile);
        this.emit("profiles:changed", this.profiles);
    }
    removeRateProfile(profile: RateProfileType) {
        const profiles = this.profiles.filter(p => p.name !== profile.name);
        storage.setAny("rate_profiles", profiles);
        if(this.profiles.length === 0) {
            this.addDefaultProfile();
        } else {
            this.selectRateProfile(this.profiles[0]);
        }
        this.emit("profile:removed", profile);
        this.emit("profiles:changed", this.profiles);
    }
    inputsToRates(inputs: ControllerOutput): ControllerOutput {
        if (!this.profile || !inputs) return inputs;
        
        return {
            yaw: inputToDegres(
                this.profile.type,
                inputs.yaw,
                this.profile.values["yaw"]
            ),
            roll: inputToDegres(
                this.profile.type,
                inputs.roll,
                this.profile.values["roll"]
            ),
            pitch: inputToDegres(
                this.profile.type,
                inputs.pitch,
                this.profile.values["pitch"]
            ),
            throttle: convertRange(inputs.throttle, -1, 1, 0, 100)
        };
    }
    getMinMaxRates() {
        const rates = this.profile.values;
        return {
            yaw: {
                min: inputToDegres(this.profile.type, -1, this.profile.values["pitch"]),
                max: inputToDegres(this.profile.type, 1, this.profile.values["pitch"])
            },
            roll: {
                min: inputToDegres(this.profile.type, -1, this.profile.values["roll"]),
                max: inputToDegres(this.profile.type, 1, this.profile.values["roll"])
            },
            pitch: {
                min: inputToDegres(this.profile.type, -1, this.profile.values["pitch"]),
                max: inputToDegres(this.profile.type, 1, this.profile.values["pitch"])
            }
        }
    }
    getNormalizedRates(inputs: ControllerOutput): ControllerOutput {
        const minMax = this.getMinMaxRates();
        return {
            yaw: convertRange(inputs.yaw, minMax.yaw.min, minMax.yaw.max, -1, 1),
            roll: convertRange(inputs.roll, minMax.roll.min, minMax.roll.max, -1, 1),
            pitch: convertRange(inputs.pitch, minMax.pitch.min, minMax.pitch.max, -1, 1),
            throttle: inputs.throttle
        };

    }
    private addDefaultProfile() {
        const defaultProfile = {... betaflightDefault, id: uuid.v7(), name: "Default" }
        this.addRateProfile(defaultProfile);
        this.selectRateProfile(defaultProfile);
        return defaultProfile;
    }
    get profile() {
        if(!this._profile) {
            if(!this.profiles.length) {
                this._profile = this.addDefaultProfile();
            }
            const profileId = storage.getString("selected_rate_profile_id");            
            this._profile = this.profiles.find(p => p.id === profileId)!;
            if(!this._profile) {
                this._profile = suggestedRates.find(p => p.id === profileId)!;
            }
        }
        return this._profile;
    }
}

export const ratesState = new RatesState();