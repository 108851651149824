export const ValueBar = ({ value }: {value: number}) => {
    let left = 50
    let right = 50

    if (value > 0) {
        left = 50
        right = 50 + value * -50
    } else {
        left = 50 + value * 50
        right = 50
    }
    return (
        <div
            className="value-bar"
            style={{ left: `${left}%`, right: `${right}%` }}
        ></div>
    )
}
