import { Gamepad, GamepadManager, VirtualJoystick } from "@babylonjs/core";
import EventEmitter from "@foxify/events";
import { getDefaultAxisMapping } from "./default-axis-mappings";
import { axesToTRYP, normalizeTrypValues, SticksValues, trypValueGenerator, TRYPValues } from "./util";

export type AxisSettings = {
    min: number;
    max: number;
    mid: number;
    deadzone: number;
    invert: boolean;
    index: number;
}
export type ControllerSettings = {
    mapping: TRYPValues<number>;
    deadzones: TRYPValues<number>;
    invert: TRYPValues<boolean>;
    min: TRYPValues<number>;
    max: TRYPValues<number>;
    mid: TRYPValues<number>;
}


export class Controller extends EventEmitter<{
    "gamepads:change": (gamepads: Gamepad[]) => void,
    "connected": () => void,
    "disconnected": () => void,
    "gamepad:selected": (gamepad: Gamepad | undefined) => void,
    "settings:change": (settings?: ControllerSettings) => void,
}> {
    gamepadManager: GamepadManager;
    public static LOCAL_STORAGE_KEY = "rb:controllerSettings";
    public isConnected: boolean = false;
    public selectedGamepadId?: string;
    public selectedGamepad?: Gamepad;
    public gamepads: Gamepad[] = [];
    public settings?: ControllerSettings;
    public isVirtual: boolean = false;
    private leftVirtualStick?: VirtualJoystick;
    private rightVirtualStick?: VirtualJoystick;
    public stickValues?: SticksValues<number>;
    public trypValues?: TRYPValues<number>;

    constructor() {
        super();
        this.loadSettings();
        console.log("Selected gamepad", this.selectedGamepadId);

        this.gamepadManager = new GamepadManager();
        this.gamepadManager.gamepads.forEach((gamepad) => {

        });
        this.gamepadManager.onGamepadConnectedObservable.add(this.onGamepadConnected.bind(this));
        this.gamepadManager.onGamepadDisconnectedObservable.add(this.onGamepadDisconnected.bind(this));
        // this.leftVirtualStick = new VirtualJoystick(true);
        // this.rightVirtualStick = new VirtualJoystick(false);

    }
    saveSettings() {
        localStorage.setItem(Controller.LOCAL_STORAGE_KEY, JSON.stringify({
            selectedGamepadId: this.selectedGamepadId,
        }));
    }
    getTRYPSettings() {
        if (!this.settings) {
            return undefined;
        }

        return {
            throttle: {
                min: this.settings.min.throttle,
                max: this.settings.max.throttle,
                mid: this.settings.mid.throttle,
                deadzone: this.settings.deadzones.throttle,
                invert: this.settings.invert.throttle,
                index: this.settings.mapping.throttle,
            },
            roll: {
                min: this.settings.min.roll,
                max: this.settings.max.roll,
                mid: this.settings.mid.roll,
                deadzone: this.settings.deadzones.roll,
                invert: this.settings.invert.roll,
                index: this.settings.mapping.roll,
            },
            yaw: {
                min: this.settings.min.yaw,
                max: this.settings.max.yaw,
                mid: this.settings.mid.yaw,
                deadzone: this.settings.deadzones.yaw,
                invert: this.settings.invert.yaw,
                index: this.settings.mapping.yaw,
            },
            pitch: {
                min: this.settings.min.pitch,
                max: this.settings.max.pitch,
                mid: this.settings.mid.pitch,
                deadzone: this.settings.deadzones.pitch,
                invert: this.settings.invert.pitch,
                index: this.settings.mapping.pitch,
            }

        }
    }

    getAxesValues(): { [key: number]: number } {
        if (this.isVirtual && this.leftVirtualStick && this.rightVirtualStick) {
            return {
                0: this.leftVirtualStick.deltaPosition.x,
                1: this.leftVirtualStick.deltaPosition.y,
                2: this.rightVirtualStick.deltaPosition.x,
                3: this.rightVirtualStick.deltaPosition.y,
            }
        }
        if (this.selectedGamepad) {
            return this.selectedGamepad.browserGamepad.axes;
        }
        return {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
        }
    }
    getTRYPRawValues() {
        if (!this.settings) {
            return {
                throttle: 0,
                roll: 0,
                yaw: 0,
                pitch: 0,
            }
        }
        return axesToTRYP(this.getAxesValues(), this.settings.mapping);
    }
    getTRYPValues() {
        if (!this.settings) {
            return {
                throttle: 0,
                roll: 0,
                yaw: 0,
                pitch: 0,
            }
        }
        return normalizeTrypValues(this.getTRYPRawValues(), this.settings);
    }

    loadSettings() {
        const settings = localStorage.getItem(Controller.LOCAL_STORAGE_KEY);
        if (settings) {
            const parsedSettings = JSON.parse(settings);
            this.selectedGamepadId = parsedSettings.selectedGamepadId;
            if (this.selectedGamepadId) {
                this.loadControllerSettings(this.selectedGamepadId);
            }
        }
    }
    private loadControllerSettings(gamepadId: string) {
        console.log("Loading controller settings for", gamepadId);
        const settings = localStorage.getItem(`gamepad:${gamepadId}`);
        if (settings) {
            const parsedSettings = JSON.parse(settings);
            this.settings = parsedSettings;
        } else {
            let defaultControllerSettings: ControllerSettings = {
                deadzones: trypValueGenerator(0.01),
                invert: trypValueGenerator(false),
                min: trypValueGenerator(-1),
                max: trypValueGenerator(1),
                mid: trypValueGenerator(0),
                mapping: getDefaultAxisMapping(gamepadId),
            }
            this.settings = defaultControllerSettings;
            // this.saveControllerSettings(gamepadId);
        }
    }
    saveControllerSettings(gamepadId: string) {
        localStorage.setItem(`gamepad:${gamepadId}`, JSON.stringify(this.settings));
        this.emit("settings:change", this.settings);
    }
    selectGamepad(gamepadId: string) {
        this.selectedGamepadId = gamepadId;
        this.saveSettings();
    }
    get connected() {
        return (this.selectedGamepad && this.selectedGamepad.isConnected) || (this.isVirtual && this.leftVirtualStick && this.rightVirtualStick);
    }

    private onGamepadConnected(gamepad: Gamepad) {
        this.gamepads = this.gamepadManager.gamepads;
        this.emit("gamepads:change", this.gamepads);

        if (this.selectedGamepadId === gamepad.id) {
            this.selectedGamepad = gamepad;
            this.emit("gamepad:selected", gamepad);
            this.emit("connected");
        }
    }
    private onGamepadDisconnected(gamepad: Gamepad) {
        this.gamepads = this.gamepadManager.gamepads;
        this.emit("gamepads:change", this.gamepads);
        console.log("Gamepad disconnected", gamepad.id);
        const currentGamepad = this.gamepads.find((gp) => gp.id === this.selectedGamepadId);

        if (!currentGamepad?.isConnected) {
            this.emit("disconnected");
            currentGamepad?.dispose();
        }
    }



}