export const showSpinner = () => {
    const spinner = document.getElementById("spinner");
    if (spinner) {
        spinner.style.display = "flex";
    }
}
export const hideSpinner = () => {
    const spinner = document.getElementById("spinner");
    if (spinner) {
        spinner.style.display = "none";
    }
}