import {
  MeshBuilder,
  PhysicsImpostor,
  Scene
} from "@babylonjs/core";
import { materialUtil } from "../../MaterialUtil";
import { Checkpoint } from "../Checkpoint";
import { Obstacle } from "../Obstacle";
import { GatesConstants, getGatesSizes } from "./GatesConstants";

const mgpFlagName = (isbig: boolean) => `MGPFlag${isbig ? 'Big' : 'Small'}`;

export class MGPFlag extends Obstacle {
  constructor(name: string, scene: Scene, isBig: boolean = false) {
    super(mgpFlagName(isBig), scene);

    const { sideHeight, sidePanelSize } = getGatesSizes(isBig);
    const width = sidePanelSize * 2;
    const height = sideHeight * 2;
    const depth = GatesConstants.DEPTH;
    const flagMesh = MeshBuilder.CreateBox("Flag", { width, height, depth, updatable: false }, scene);
    const checkpoint = new Checkpoint("checkpoint", { shape: "square", width: height, height, depth }, scene);
    flagMesh.physicsImpostor = new PhysicsImpostor(flagMesh, PhysicsImpostor.BoxImpostor, { mass: 0, restitution: 0.9 }, scene);
    checkpoint.setParent(this);
    flagMesh.setParent(this);

    flagMesh.position.y = sideHeight;

    checkpoint.position.x = height / 2 + sidePanelSize;

    this.coliders = [flagMesh];
    this.checkpoints.push(checkpoint)
    materialUtil.applyVerticalMaterial(flagMesh, scene);
  }
}
