import { EventState, Scene, Vector3 } from "@babylonjs/core";
import { AdvancedDynamicTexture, Control, TextBlock } from "@babylonjs/gui";
import { Drone } from "../logic/Drone";
import { IPlugin } from "./IPlugin";

export class SpeedOSDPlugin implements IPlugin {
    private lastTimestamp = performance.now();
    private lastPosition?: Vector3;
    private kmhText?: TextBlock;
    private heightText?: TextBlock;
    private fpsText?: TextBlock;
    private prevousFps: number = 0;
    private prevousSpeed: number = 0;
    constructor(private scene: Scene) {

    }
    load(): void {
        let advancedTexture: AdvancedDynamicTexture = this.scene.metadata.gui;

        this.scene.onBeforeRenderObservable.add(this.beforeRenderHandler.bind(this));

        this.fpsText = this.generateLeftTextBlock("FPS: 0", 10);
        advancedTexture.addControl(this.fpsText);

        // Create the FPS text block
        this.kmhText = this.generateLeftTextBlock("KMH: 0", 50);
        advancedTexture.addControl(this.kmhText);

        this.heightText = this.generateLeftTextBlock("ALT: 0", 100);
        advancedTexture.addControl(this.heightText);
    }
    dispose(): void {
        this.scene.onBeforeRenderObservable.removeCallback(this.beforeRenderHandler);
    }
    generateLeftTextBlock(text: string, padding: number): TextBlock {
        const textText = new TextBlock();
        textText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        textText.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        textText.text = text;
        textText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        textText.fontFamily = "monospace";
        textText.color = "white";
        textText.height = "20px";
        textText.width = "100px";
        // 
        textText.paddingBottom = `${padding}px`;
        textText.left = "10px";
        return textText;
    }

    beforeRenderHandler(scene: Scene, eventState: EventState) {
        const currentFps = Math.floor(scene.getEngine().getFps());
        if (Math.abs(currentFps - this.prevousFps) > 2) {
            this.prevousFps = currentFps;
            if (this.fpsText) this.fpsText.text = `FPS: ${currentFps}`;
        }

        const drone = scene.getTransformNodeByName("player") as Drone;
        if (!drone) return;
        if (!this.lastPosition) {
            this.lastPosition = drone.mesh.position.clone();
            return;
        }
        const currentPosition = drone.mesh.position.clone();
        const currentTimestamp = performance.now();
        const deltaTime = currentTimestamp - this.lastTimestamp;

        const mdistance = Vector3.Distance(this.lastPosition, currentPosition)
        const currentSpeed = Math.floor(mdistance / (deltaTime / 1000) * 3.6);
        if (Math.abs(currentSpeed - this.prevousSpeed) > 1) {
            this.prevousSpeed = currentSpeed;
            if (this.kmhText) this.kmhText.text = `KMH: ${currentSpeed}`
        }


        this.lastPosition = currentPosition;
        this.lastTimestamp = currentTimestamp;

        if (this.heightText) this.heightText.text = `ALT: ${Math.round(currentPosition.y)}m`

    }
}