import { Scene, Tools } from "@babylonjs/core";
import { gameState } from "../../state/GameState";
import { IPlugin } from "./IPlugin";

export class ScreenshotPlugin implements IPlugin {
    constructor(private scene: Scene) { }
    load(): void {
        gameState.on("take:screenshot", this.takeObstacleImage.bind(this));
    }
    dispose(): void {
        gameState.off("take:screenshot", this.takeObstacleImage.bind(this));
    }
    async takeObstacleImage() {
        if (!this.scene) return;

        const engine = this.scene.getEngine();
        const camera = this.scene.activeCamera;
        if (!camera) return;
        const canvas = engine.getRenderingCanvas();
        if (!canvas) return;

        Tools.CreateScreenshot(engine, camera, { precision: 1 }, async (data) => {
            // const blob = await this.cropSrcImage(data, 0, 0, 100, 100);
            const img = await this.srcToImage(data);
            const { width, height } = canvas
            const size = 200;
            const blob = await this.cropSrcImage(img, width / 2 - size / 2, height / 2 - size / 2 - 50, size, size);
            const name = this.scene.getNodes().filter((node) => (node.name.startsWith("MGP")))[0].name;
            console.log(name);
            Tools.Download(blob, `${name}.png`);
        }, "image/png", false);
    }
    async cropSrcImage(image: any, x: number, y: number, width: number, height: number) {
        return new Promise<Blob>((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            canvas.width = width;
            canvas.height = height;
            if (context) {
                context.drawImage(image as any, x, y, width, height, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    if (!blob) {
                        reject(new Error('Canvas to blob failed'));
                        return;
                    }
                    resolve(blob);
                });
            }
        });
    }
    srcToImage(src: string) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve(img);

            };
            img.onerror = reject;
            img.src = src;
        });
    }

}