import { Mesh, Scene, Tags } from "@babylonjs/core";
import { GameNode } from "../GameNode";
import { Checkpoint } from "./Checkpoint";


export abstract class Obstacle extends GameNode {
    public coliders: Mesh[] = [];
    public checkpoints: Checkpoint[] = [];
    public order: number = 0;

    constructor(name: string, scene: Scene) {
        super(name, scene);

        Tags.AddTagsTo(this, "obstacle");
    }
    allowScaling() {
        return false;
    }
}