import { CSSProperties } from 'react'
import Button from 'react-bootstrap/Button'

const descriptionStyle: CSSProperties = {
    color: 'white',
    fontSize: '12px',
}
export type MenuButtonProps = {
    text: string
    onClick?: () => void
    description?: string
}

export const MenuButton = ({ text, onClick, description }: MenuButtonProps) => {
    return (
        <Button onClick={onClick}>
            {text}
            <div style={descriptionStyle}>{description}</div>
        </Button>
    )
}
