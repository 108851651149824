import {
    ExtrudePolygon,
    Mesh,
    Scene,
    Vector3
} from "@babylonjs/core";
import earcut from "earcut";
import { GameNode } from "../../GameNode";



export class Arrow extends GameNode {
    arrow: Mesh;
    constructor(name: string, scene: Scene) {
        super(name, scene);
        this.addScalingProps();
        const depth = 0.3;
        const size = 0.5;
        //should create an arrow with path and to extrude it
        const path = [
            new Vector3(-size, 0, -size),
            new Vector3(0, 0, size),
            new Vector3(size, 0, -size),
            new Vector3(0, 0, -size / 2),
            new Vector3(-size, 0, -size)
        ];

        // create mesh from path in babylonjs and extrude it

        this.arrow = ExtrudePolygon("arrow", { shape: path, sideOrientation: Mesh.DOUBLESIDE, depth: depth }, scene, earcut);
        this.arrow.position.y = depth;
        this.arrow.setParent(this);
    }

    allowScaling(): boolean {
        return false;
    }
}
