import { AssetsManager, CubeTexture, Scene, Texture } from "@babylonjs/core";
import { config } from "../../config";
import { materialUtil } from "../scene/MaterialUtil";

export type TextureDescription = {
    name: string;
    url: string;
}

export class AssetsLoader {
    constructor(private scene: Scene) { }
    load(textures: TextureDescription[], cubeTextures: TextureDescription[]) {
        return new Promise((resolve, reject) => {
            const assetsManager = new AssetsManager(this.scene);

            textures.forEach(texture => {
                assetsManager.addTextureTask(texture.name, texture.url)
            });
            cubeTextures.forEach(cubeTexture => {
                assetsManager.addCubeTextureTask(cubeTexture.name, cubeTexture.url, undefined, true);
            });
            assetsManager.onFinish = (tasks) => {
                tasks.forEach(task => {
                    if ((task as any).texture instanceof Texture) {
                        materialUtil.addTexture(task.name, (task as any).texture);
                    }
                    if ((task as any).texture instanceof CubeTexture) {
                        materialUtil.addCubeTextureSkybox(task.name, (task as any).texture, this.scene);
                    }
                });
                resolve(true);
            }
            assetsManager.load();
        });
    }
    async loadAll() {
        const url = config.assetsUrl;

        await this.load([
            { name: 'gate:horizontal', url: `${url}/3d/mgp/logo.png` },
            { name: 'gate:vertical', url: `${url}/3d/mgp/side.png` },
            { name: 'hurdle', url: `${url}/3d/mgp/hurdle.png` },
        ], [
            { name: 'factory_day', url: `${url}/3d/skyboxes/factory_day/factory_day` },
        ]);
    }

}