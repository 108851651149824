import { Scene, Tools, TransformNode, Vector3 } from "@babylonjs/core";
import { decimals } from "./util";

export type GameNodeProp = {
    name: string;
    defaultValue?: any;
    get: () => any;
    set: (value: any) => void;
}

export abstract class GameNode extends TransformNode {
    private _props: GameNodeProp[] = [];
    private onChangedHandlers: (() => void)[] = [];

    constructor(name: string, scene: Scene) {
        super(name, scene);

        this.addProp({ name: "name", defaultValue: name, get: () => this.name, set: (value) => { this.name = value } });
        this.addProp({ name: "rotation.x", get: () => decimals(Tools.ToDegrees(this.rotation.x), 0), set: (value) => { this.rotation.x = Tools.ToRadians(decimals(value, 0)) } });
        this.addProp({ name: "rotation.y", get: () => decimals(Tools.ToDegrees(this.rotation.y), 0), set: (value) => { this.rotation.y = Tools.ToRadians(decimals(value, 0)) } });
        this.addProp({ name: "rotation.z", get: () => decimals(Tools.ToDegrees(this.rotation.z), 0), set: (value) => { this.rotation.z = Tools.ToRadians(decimals(value, 0)) } });
        this.addProp({ name: "position.x", get: () => decimals(this.position.x, 2), set: (value) => { this.position.x = value } });
        this.addProp({ name: "position.y", get: () => decimals(this.position.y, 2), set: (value) => { this.position.y = value } });
        this.addProp({ name: "position.z", get: () => decimals(this.position.z, 2), set: (value) => { this.position.z = value } });
    }
    addOnChangedHandler(handler: () => void) {
        this.onChangedHandlers.push(handler);
    }
    onChanged() {
        this.onChangedHandlers.forEach(handler => handler());
    }
    removeOnChangedHandler(handler: () => void) {
        this.onChangedHandlers = this.onChangedHandlers.filter(h => h !== handler);
    }
    addProp(prop: GameNodeProp) {
        this._props.push(prop);
    }
    getProps() {
        return this._props.map(prop => {
            return {
                ...prop,
                set: (value: any) => {
                    prop.set(value);
                    this.onChanged();
                }
            }
        });
    }
    getProp(name: string) {
        return this._props.find(prop => prop.name === name);
    }
    getWorldForward(): Vector3 {
        const worldForward = new Vector3();
        this.getDirectionToRef(new Vector3(0, 0, -1), worldForward);
        return worldForward;
    }
    addScalingProps() {
        this.addProp({ name: "scaling.x", get: () => decimals(this.scaling.x, 2), set: (value) => { this.scaling.x = value } });
        this.addProp({ name: "scaling.y", get: () => decimals(this.scaling.y, 2), set: (value) => { this.scaling.y = value } });
        this.addProp({ name: "scaling.z", get: () => decimals(this.scaling.z, 2), set: (value) => { this.scaling.z = value } });
    }
    abstract allowScaling(): boolean;

}