import { Vector3 } from "@babylonjs/core";
import { ControllerOutput } from "../../types";

type PidConfig = {
    Kp: number;
    Ki: number;
    Kd: number;
    Kff: number;
    Dmax: number;
};
export class PIDController {
    private previousError: number;
    private previousInput: number;
    private integral: number;
    private Kp: number;
    private Ki: number;
    private Kd: number;
    private Kff: number;  // Feedforward gain
    private Dmax: number; // Maximum derivative term

    constructor(config: PidConfig) {
        this.Kp = config.Kp;
        this.Ki = config.Ki;
        this.Kd = config.Kd;
        this.Kff = config.Kff;
        this.Dmax = config.Dmax;
        this.previousError = 0;
        this.previousInput = 0;
        this.integral = 0;
    }

    public calculate(current: number, desired: number): number {
        let error = desired - current;

        this.integral += error;
        let derivative = error - this.previousError;
        this.previousError = error;

        // Limit the derivative term to Dmax
        derivative = Math.max(-this.Dmax, Math.min(this.Dmax, derivative));

        // Calculate the feedforward term
        let feedforward = this.Kff * (desired - this.previousInput);
        this.previousInput = desired;

        return this.Kp*error + this.Ki*this.integral + this.Kd*derivative + feedforward;
    }
}

export class FC_PIDController {
    private yaw: PIDController;
    private roll: PIDController;
    private pitch: PIDController;

    constructor() {
        // this.yaw = new PIDController({Kp: 0.1, Ki: 0.0, Kd: 0.0, Dmax: 18, Kff: 120});
        // this.roll = new PIDController({Kp: 0.1, Ki: 0.0, Kd: 0.0, Dmax: 18, Kff: 120});
        // this.pitch = new PIDController({Kp: 0.1, Ki: 0.0, Kd: 0.0, Dmax: 18, Kff: 120});
        
        this.yaw = new PIDController({Kp: 45, Ki: 80, Kd: 18, Dmax: 18, Kff: 120});
        this.roll = new PIDController({Kp: 45, Ki: 80, Kd: 18, Dmax: 18, Kff: 120});
        this.pitch = new PIDController({Kp: 45, Ki: 80, Kd: 18, Dmax: 18, Kff: 120});
    }
    calculate(desired: ControllerOutput, actual: ControllerOutput): Vector3 {
 // let desiredAngularVelocityX = inputsWithRates.pitch * DEG_TO_RAD;
        // let desiredAngularVelocityY = inputsWithRates.yaw * DEG_TO_RAD; 
        // let desiredAngularVelocityZ = - inputsWithRates.roll * DEG_TO_RAD; 

        return new Vector3(
            this.pitch.calculate(desired.pitch, actual.pitch) ?? 0,
            this.yaw.calculate(desired.yaw, actual.yaw) ?? 0,
            this.roll.calculate(desired.roll, actual.roll) ?? 0
        );
    }
}

