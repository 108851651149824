import { useEffect, useState } from 'react'
import './NodeDetails.css'
import { GameNode, GameNodeProp } from '../../engine/scene/GameNode'
export const NodeDetails = ({ node }: { node: GameNode }) => {
    const [nodeProps, setNodeProps] = useState<GameNodeProp[]>(node.getProps())

    const onNodeChange = () => {
        setNodeProps([...node?.getProps()])
    }
    useEffect(() => {
        if (!node) return

        node.addOnChangedHandler(onNodeChange)
        return () => {
            node.removeOnChangedHandler(onNodeChange)
        }
    }, [node])
    const hiddenProps = ['name']
    const propsMap = nodeProps.reduce((acc, prop) => {
        acc[prop.name] = prop
        return acc
    }, {} as { [key: string]: GameNodeProp })

    if (!node || !propsMap) return <></>
    return (
        <div className="node-details">
            {node
                .getProps()
                .filter((prop) => !hiddenProps.includes(prop.name))
                .map((prop, index: number) => (
                    <div key={index} className="row">
                        <label className="col-6" htmlFor={prop.name}>
                            {prop.name}
                        </label>
                        <input
                            className="col-6"
                            name={prop.name}
                            value={prop.get()}
                            onChange={(e) => prop.set(e.currentTarget.value)}
                        />
                    </div>
                ))}
        </div>
    )
}
