import { Color3, CubeTexture, Material, Mesh, MultiMaterial, Scene, StandardMaterial, SubMesh, Texture } from "@babylonjs/core";


class MaterialUtil {
    private horizontalMaterial?: MultiMaterial;
    private verticalMaterial?: MultiMaterial;
    private hurdleMaterial?: MultiMaterial;
    private materials: { [key: string]: MultiMaterial } = {};
    private textures: { [key: string]: Texture } = {};
    private cubeTextures: { [key: string]: CubeTexture } = {};
    private skyboxMaterials: { [key: string]: Material } = {};

    constructor() {}
    addTexture(name: string, texture: Texture) {
        this.textures[`${name}:front`] = texture;
        this.textures[`${name}:back`] = texture.clone();
        this.textures[`${name}:back`].uScale = -1; 
        this.textures[`${name}:back`].vScale = -1;
    }
    addCubeTextureSkybox(name: string, texture: CubeTexture, scene: Scene) {
        var skyboxMaterial = new StandardMaterial("skyBoxMaterial", scene);
        skyboxMaterial.backFaceCulling = false;
        // skyboxMaterial.allowShaderHotSwapping = false;
        skyboxMaterial.reflectionTexture = texture;
        skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
        skyboxMaterial.disableLighting = true;
        skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
        skyboxMaterial.specularColor = new Color3(0, 0, 0);        
        this.skyboxMaterials[name] = skyboxMaterial;
    }
    addSkyboxMaterial(name: string, material: Material) {
        this.skyboxMaterials[name] = material;
    }
    getSkyboxMaterial(name: string) {
        return this.skyboxMaterials[name];
    }
    
    getHorizontalMaterial(scene: Scene) {
        if (!this.horizontalMaterial) {
            this.horizontalMaterial = this
                .getTexturedMaterial(scene, `gate:horizontal:front`, `gate:horizontal:back`);
        }
        return this.horizontalMaterial;
    }

    getVerticallMaterial(scene: Scene) {
        if (!this.verticalMaterial) {
            this.verticalMaterial = this
                .getTexturedMaterial(scene, `gate:vertical:front`, `gate:vertical:back`);
        }
        return this.verticalMaterial;
    }
    getHurdleMaterial(scene: Scene) {
        if (!this.hurdleMaterial) {
            this.hurdleMaterial = this.getTexturedMaterial(scene, `hurdle:front`, `hurdle:back`);            
        }
        return this.hurdleMaterial;
    }
    
    applyHurdleMaterial(mesh: Mesh, scene: Scene) {
        return this.applyMaterial(mesh, scene, this.getHurdleMaterial(scene));
    }
    applyHorizontalMaterial(mesh: Mesh, scene: Scene) {
        this.applyMaterial(mesh, scene, this.getHorizontalMaterial(scene));
    }
    applyVerticalMaterial(mesh: Mesh, scene: Scene) {
        this.applyMaterial(mesh, scene, this.getVerticallMaterial(scene));
    }

    private applyMaterial(mesh: Mesh, scene: Scene, material: MultiMaterial) {
        mesh.material = material;
  
        // Update the box's subMeshes to use the multi material
        mesh.subMeshes = [];
        let verticesCount = mesh.getTotalVertices();
        for (let i = 0; i < 6; i++) {
            new SubMesh(i, 0, verticesCount, 6 * i, 6, mesh);
        }
    }

    private getTexturedMaterial(scene: Scene, frontTexture: string, backTexture?: string) {
        const material = new MultiMaterial('multiMat', scene);
        backTexture = backTexture || frontTexture;

        const frontMaterial = new StandardMaterial('frontMat');
        frontMaterial.diffuseTexture = this.textures[`${frontTexture}`];;  

        const backMaterial = new StandardMaterial('backMat');
        backMaterial.diffuseTexture = this.textures[`${backTexture}`];

        const otherSidesMaterial = new StandardMaterial('otherSidesMat');
        otherSidesMaterial.diffuseColor = new Color3(0.6, 0.6, 0.6);
        // material.subMaterials.push(otherSidesMaterial);
        material.subMaterials.push(backMaterial);
        material.subMaterials.push(frontMaterial);
        // material.subMaterials.push(otherSidesMaterial);
        material.subMaterials.push(otherSidesMaterial);
        material.subMaterials.push(otherSidesMaterial);
        material.subMaterials.push(otherSidesMaterial);
        material.subMaterials.push(otherSidesMaterial);
        return material;

    }
    clear() {
        this.horizontalMaterial = undefined;
        this.verticalMaterial = undefined;
        this.hurdleMaterial = undefined;
        this.materials = {};
        this.textures = {};
        this.cubeTextures = {};
        this.skyboxMaterials = {};
    }
}
export const materialUtil = new MaterialUtil();