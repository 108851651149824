import { Gamepad } from "@babylonjs/core";
import { useEffect, useState } from "react";
import { AxisSettings, Controller } from "../../../engine/gamepad/Controller";
import { TRYPValues } from "../../../engine/gamepad/util";
import "./ControllerSettings.scss";
import { TRYPRow } from "./TRYPRow";

type ControllerSettingsProps = {
  focused: boolean;
  onUp?: () => void;
};
type TRYPSettings = {
  throttle: AxisSettings;
  yaw: AxisSettings;
  pitch: AxisSettings;
  roll: AxisSettings;
};

type TRYPAxis = "throttle" | "yaw" | "pitch" | "roll";
export const ControllerSettingsTab = ({
  focused,
  onUp,
}: ControllerSettingsProps) => {
  const [controller, setController] = useState<Controller>();
  const [gamepads, setGamepads] = useState<Gamepad[]>([]);
  const [selectedId, setSelectedId] = useState<string>();
  const [selectedGamepad, setSelectedGamepad] = useState<Gamepad>();
  const [trypSettings, setTrpySettings] = useState<TRYPSettings>();
  const [trypRawValues, setTrpyRawValues] = useState<TRYPValues<number>>();
  const [axesValues, setAxesValues] = useState<{ [key: number]: number }>();
  const [trypValues, setTrpyValues] = useState<TRYPValues<number>>();

  useEffect(() => {
    // Need to add navigation wia arrow keys
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!focused) return;
      // let tabIndex = tabs.findIndex((tab) => tab.key === selectedTab)
      if (e.key === "ArrowRight") {
        // newTabKey = tabs[(tabIndex + 1) % tabs.length].key
      }
      if (e.key === "ArrowLeft") {
        // newTabKey = tabs[(tabIndex - 1 + tabs.length) % tabs.length].key
      }
      // if (newTabKey !== selectedTab) {
      //     setSelectedTab(newTabKey)
      //     if (onTabChange) onTabChange(newTabKey)
      // }
      // if (e.key === 'ArrowDown' && onDown) onDown()
      // if (e.key === 'ArrowUp' && onUp) onUp()
    };
    window.addEventListener("keyup", handleKeyPress);

    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [focused]);
  useEffect(() => {
    let _controller = new Controller();
    setController(_controller);
    setGamepads(_controller.gamepadManager.gamepads);
    setTrpySettings(_controller.getTRYPSettings());
    setSelectedId(_controller.selectedGamepadId);
    _controller.on("gamepads:change", (_gamepads) =>
      setGamepads([..._gamepads])
    );
    _controller.on("gamepad:selected", (gamepad) => {
      setSelectedGamepad(gamepad ?? undefined);
      setSelectedId(gamepad?.id ?? undefined);
    });
    _controller.on("settings:change", (settings) => {
      setTrpySettings(_controller.getTRYPSettings());
    });
    const interval = setInterval(() => {
      setTrpyValues({ ..._controller.getTRYPValues() });
      setTrpyRawValues({ ..._controller.getTRYPRawValues() });
      setAxesValues({ ..._controller.getAxesValues() });
    }, 1);
    return () => {
      clearInterval(interval);
      // controller.destroy()
    };
  }, []);
  const visible = true;
  const onAxisChange = (axis: TRYPAxis, i: number) => {
    if (!controller) return;
    if (!controller.selectedGamepadId) return;

    let settings = controller.settings;
    if (settings) {
      settings.mapping[axis] = i;
    }
    controller.saveControllerSettings(controller.selectedGamepadId);
  };
  const onAxisInvert = (axis: TRYPAxis, invert: boolean) => {
    if (!controller) return;
    if (!controller.selectedGamepadId) return;

    let settings = controller.settings;
    if (settings) {
      settings.invert[axis] = invert;
    }
    controller.saveControllerSettings(controller.selectedGamepadId);
  };
  return (
    <div className="controller-settings-menu">
      <div className="controller-axis">
        <div className="controller-select">
          <h5>Controller: </h5>
          <select
            value={gamepads.length > 0 ? selectedId : ""}
            onChange={(e) => controller?.selectGamepad(e.target.value)}
          >
            <option value="" disabled>
              Connect and move sticks
            </option>
            {gamepads.map((gp) => (
              <option key={gp.index} value={gp.id}>
                {gp.id.split("(Vendor:")[0]}
                {gp.isConnected === false ? " (disconnected)" : ""}
              </option>
            ))}
          </select>
        </div>
        {trypValues && trypRawValues && trypSettings && axesValues && (
          <table className="controller-axis-table">
            <tbody>
              <tr>
                <td></td>
                <td className="header">Channel</td>
                <td className="header">Invert</td>
                {/* <td className="header">Deadzone</td> */}
                <td className="header">Value</td>
              </tr>
              <TRYPRow
                title="Throttle"
                value={trypRawValues.throttle}
                settings={trypSettings.throttle}
                normalisedValue={trypValues.throttle}
                onAxisChange={(i) => onAxisChange("throttle", i)}
                onAxisInvert={(invert) => onAxisInvert("throttle", invert)}
                axis={axesValues}
              />
              <TRYPRow
                title="Yaw"
                value={trypRawValues.yaw}
                settings={trypSettings.yaw}
                normalisedValue={trypValues.yaw}
                onAxisChange={(i) => onAxisChange("yaw", i)}
                onAxisInvert={(invert) => onAxisInvert("yaw", invert)}
                axis={axesValues}
              />
              <TRYPRow
                title="Pitch"
                value={trypRawValues.pitch}
                settings={trypSettings.pitch}
                normalisedValue={trypValues.pitch}
                onAxisChange={(i) => onAxisChange("pitch", i)}
                onAxisInvert={(invert) => onAxisInvert("pitch", invert)}
                axis={axesValues}
              />
              <TRYPRow
                title="Roll"
                value={trypRawValues.roll}
                settings={trypSettings.roll}
                normalisedValue={trypValues.roll}
                onAxisChange={(i) => onAxisChange("roll", i)}
                onAxisInvert={(invert) => onAxisInvert("roll", invert)}
                axis={axesValues}
              />
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
