import { Scene } from "@babylonjs/core";
import { AdvancedDynamicTexture, Control, StackPanel, TextBlock } from "@babylonjs/gui";

export class DebugViewer {
    private debug: boolean = false;
    private gui: AdvancedDynamicTexture;
    private items: { [key: string]: { name: string, valueRender: (value: any) => string, textBlock: TextBlock } } = {};
    private stackPanel: StackPanel;
    constructor(scene: Scene) {
        this.gui = scene.metadata.gui;
        this.stackPanel = new StackPanel();
        this.stackPanel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
        this.stackPanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        this.gui.addControl(this.stackPanel);

    }
    dispose() {
        this.gui.removeControl(this.stackPanel);
    }
    setDebug(debug: boolean) {
        this.debug = debug;
    }
    getDebug() {
        return this.debug;
    }
    addDebugItem(key: string, name: string, valueRender: (value: any) => string) {
        this.items[key] = ({ valueRender, name, textBlock: this.generateTextBlock(`${name}: ___`, Object.keys(this.items).length * 55 + 10) });
        this.stackPanel.addControl(this.items[key].textBlock);
    }
    update(changes: { [key: string]: any }) {
        if (!this.debug) return;

        Object.keys(changes).forEach((key) => {
            if (this.items[key]) {
                this.items[key].textBlock.text = `${this.items[key].name}: ${this.items[key].valueRender(changes[key])}`;
            }
        });
    }

    private generateTextBlock(text: string, padding: number): TextBlock {
        const textText = new TextBlock();
        textText.text = text;
        textText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        textText.fontFamily = "monospace";
        textText.fontSize = "14px";
        textText.color = "white";
        textText.height = "20px";
        textText.width = "150px";
        // 
        return textText;
    }

}