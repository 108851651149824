import {
    Mesh,
    MeshBuilder,
    Scene,
    ShaderMaterial
} from "@babylonjs/core";
import { GameNode } from "../../GameNode";



export class Box extends GameNode {
    public box: Mesh;
    constructor(name: string, scene: Scene) {
        super(name, scene);

        this.addScalingProps();

        //Create ribbon with updatable parameter set to true for later changes
        this.box = MeshBuilder.CreateBox("box", { width: 1, height: 1, depth: 1 }, scene);
        // tube.position = new Vector3(0, height / 2, 0);
        var shaderMaterial = new ShaderMaterial("shader", scene, {
            vertex: "neonCityMaterial",
            fragment: "neonCityMaterial",
        },
            {
                attributes: ["position", "normal"],
                uniforms: ["worldViewProjection", "world", "size", "time", "scale"],
            });

        shaderMaterial.setFloat("size", 1.0);
        shaderMaterial.setVector3("scale", this.scaling);
        shaderMaterial.setFloat("time", performance.now() / 1000);

        scene.registerBeforeRender(() => {
            // it should be updated every second
            shaderMaterial.setVector3("scale", this.scaling.clone());
            shaderMaterial.setFloat("time", performance.now() / 1000);
        });

        this.box.material = shaderMaterial;
        this.box.setParent(this);
    }
    allowScaling(): boolean {
        return true;
    }
}
