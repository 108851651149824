import { AxisSettings } from "../../../engine/gamepad/Controller";
import { AxisChooser } from "./AxisChooser";
import { ValueBar } from "./ValueBar";

export const TRYPRow = ({
  title,
  value,
  axis,
  normalisedValue,
  settings,
  onAxisChange,
  onAxisInvert,
}: {
  title: string;
  value: number;
  normalisedValue: number;
  axis: { [key: number]: number };
  settings: AxisSettings;
  onAxisChange: (i: number) => void;
  onAxisInvert: (invert: boolean) => void;
}) => {
  return (
    <tr>
      <td className="header">{title}</td>
      <td>
        <AxisChooser
          value={value}
          axes={axis}
          index={settings.index}
          onChange={onAxisChange}
        />
      </td>
      <td className="text-center">
        <input
          type="checkbox"
          checked={settings.invert}
          onChange={(e) => onAxisInvert(e.currentTarget.checked)}
        />
      </td>
      {/* <td>
                <input type="number" value={settings.deadzone} />
            </td> */}
      <td>
        <div
          style={{
            position: "relative",
            display: "flex",
            height: "16px",
          }}
        >
          <ValueBar value={normalisedValue} />
        </div>
      </td>
    </tr>
  );
};
