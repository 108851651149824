import * as React from 'react'
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { useNavigate } from 'react-router'

type MenuLayoutProps = {
    children?: React.ReactNode
    menu?: React.ReactNode | React.ReactNode[]
    hideMenu?: boolean
    showBackButton?: boolean
}
export function Navigation({}) {
    const navigate = useNavigate()
    return (
        <Navbar
            expand="lg"
            className="bg-body-tertiary"
            bg="dark"
            data-bs-theme="dark"
        >
            <Container fluid>
                <Navbar.Brand href="#home">Rotor Blitz</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="public-navbar">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/flights">Flights</Nav.Link>
                        <NavDropdown title="Leaderboards" id="leaderboards-nav">
                            <NavDropdown.Item href="/leaderboards/world">
                                World wide leaderboards
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">
                                Something
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
