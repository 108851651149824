import { ControllerOutput } from "../../types";

type PidOutput = {
    roll: number;
    pitch: number;
    yaw: number;
};
  
  
export class MotorMixingTable {
    // This is a simple mixing table for a quadcopter in "X" configuration.
    // The rows represent each motor's response to [throttle, roll, pitch, yaw] respectively.
    private mixingTable = [
      [1, -1,  1, -1],  // Motor 1 (front right)
      [1, -1, -1,  1],  // Motor 2 (rear right)
      [1,  1, -1, -1],  // Motor 3 (rear left)
      [1,  1,  1,  1]   // Motor 4 (front left)
    ];
  
    // Calculates the throttle value for each motor based on the given control inputs.
    public calculate(input: ControllerOutput): number[] {
      let motorInputs = [];
      // console.log( input.throttle);
      for (let i = 0; i < this.mixingTable.length; i++) {
        let motorInput = this.mixingTable[i][0] * input.throttle / 100 +
                         this.mixingTable[i][1] * input.roll +
                         this.mixingTable[i][2] * input.pitch +
                         this.mixingTable[i][3] * input.yaw;
  
        // Ensure the motor input is between 0 and 1.
        motorInput = Math.max(0, Math.min(1, motorInput));
  
        motorInputs.push(motorInput);
      }
  
      return motorInputs;
    }
  }
  