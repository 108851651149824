import React, { useState } from "react";
import "./GameMenu.scss";
import { MenuTabs } from "./MenuTabs";
import { MenuTiles, TileItemTypes } from "./MenuTiles";
import { homepageMenuItems } from "./menu-items";

type GameMenuBaseTab = {
  key: string;
  title: string;
  image?: string;
  icon?: string | React.ReactNode;
  badge?: number;
  url: string;
  description?: string;
};

export type GameMenuTab = GameMenuBaseTab & {
  type: "custom";
  content?:
    | React.ReactNode
    | ((focused: boolean, onUp: () => void) => React.ReactNode);
};
export type TilesTab = GameMenuBaseTab & {
  type: "tiles";
  tiles: TileItemTypes[];
};
export type GameMenuTabTypes = GameMenuTab | TilesTab;
export type GameMenuProps = {};

export const GameMenu = ({}: GameMenuProps) => {
  const [focused, setFocused] = useState<"tabs" | "tiles">("tabs");
  const [selectedTab, setSelectedTab] = useState("home");
  const onTabsUp = () => setFocused("tabs");
  const onTabsDown = () => setFocused("tiles");
  const onTilesUp = () => setFocused("tabs");
  const tabs = homepageMenuItems;
  return (
    <div className="main-menu">
      <MenuTabs
        focused={focused === "tabs"}
        onUp={onTabsUp}
        onDown={onTabsDown}
        onTabChange={(tab) => setSelectedTab(tab)}
        tabs={tabs}
      />
      <div className="menu-content">
        {tabs.map((tab) => {
          const tabFocused = focused === "tabs" && selectedTab === tab.key;
          const visible = selectedTab === tab.key;
          let className = `${visible ? "" : "hidden"} ${
            focused === "tiles" ? "focused" : ""
          }`;
          if (tab.type === "custom" && visible) {
            return (
              <React.Fragment key={tab.key}>
                {typeof tab.content === "function"
                  ? tab.content(tabFocused, onTilesUp)
                  : tab.content}
              </React.Fragment>
            );
          }
          if (tab.type === "tiles") {
            return (
              <MenuTiles
                key={tab.key}
                visible={visible}
                focused={focused === "tiles"}
                onDown={onTabsDown}
                onUp={onTabsUp}
                tiles={tab.tiles}
              />
            );
          }
        })}
      </div>
      <div className="menu-footer">
        <div>Footer</div>
      </div>
    </div>
  );
};
