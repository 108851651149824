var pjson = require('../../package.json');

export const app = {
    version: () => pjson.version,
    isCordova: function() {
        return (window as any)['cordova'] !== undefined;
    },
    isElectron: function() {
        return (window as any)['bridge'] !== undefined;
    },
    platform: function() {
        // if(this.isElectron()) {
        //     var os = require('os');
        //     console.log(os.arch(), os.platform(), os.type(), os.release());
        //     return os.platform();
        //     // return window['process']?.platform
        // }
        
        // return (window as any)['cordova']?.platformId ?? "browser";
    },
    device: function() {
        return (window as any)['device']?.model ?? "browser";
    }

}
