import { Scene } from "@babylonjs/core";
import { Control, TextBlock } from "@babylonjs/gui";
import { gameState } from "../../state/GameState";
import { Checkpoint } from "../scene/obstacles/Checkpoint";
import { Obstacle } from "../scene/obstacles/Obstacle";
import { IPlugin } from "./IPlugin";



export class GameplayPlugin implements IPlugin {
    private startText: TextBlock;
    private obstacles: Obstacle[] = [];
    private checkpoints: Checkpoint[] = [];
    private checkpointIndex: number = -1;
    constructor(private scene: Scene) {
        this.startText = new TextBlock();

    }
    load(): void {
        this.startText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        this.startText.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
        this.startText.text = "Press any key to start";
        this.startText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
        this.startText.fontFamily = "monospace";
        this.startText.fontSize = "40px";
        this.startText.color = "white";
        this.startText.height = "40px";
        // this.startText.width = "100px";
        this.startText.isVisible = false;

        this.scene.metadata.gui.addControl(this.startText);
        gameState.on("start", this.startGame.bind(this));
        gameState.on("pause", this.pauseGame.bind(this));
        gameState.on("checkpoint:passed", this.checkpointPassed.bind(this));

    }

    dispose(): void {
        gameState.off("start", this.startGame.bind(this));
        gameState.off("pause", this.pauseGame.bind(this));
        gameState.off("checkpoint:passed", this.checkpointPassed.bind(this));
        this.scene.metadata.gui.removeControl(this.startText);
    }

    startGame() {
        this.runStartAnimation();
        this.obstacles = this.scene.getTransformNodesByTags("obstacle") as Obstacle[];
        this.obstacles = this.obstacles.sort((a, b) => a.order - b.order)
        this.checkpoints = this.obstacles.flatMap(obstacle => obstacle.checkpoints);
        this.checkpointIndex = 0;
        this.checkpoints.forEach(checkpoint => checkpoint.setVisibile(false));
        this.checkpoints[this.checkpointIndex].setVisibile(true);

    }
    checkpointPassed(checkpoint: Checkpoint) {
        if (checkpoint === this.checkpoints[this.checkpointIndex]) {
            this.nextCheckpoint();
        }
    }
    nextCheckpoint() {
        // Hide old checkpoint

        this.checkpoints[this.checkpointIndex].setVisibile(false);

        if (this.checkpointIndex === this.checkpoints.length - 1) {
            this.checkpointIndex = 0;
        } else {
            this.checkpointIndex++;
        }

        this.checkpoints[this.checkpointIndex].setVisibile(true);
    }
    pauseGame() {
    }

    private runStartAnimation() {
        this.startText.text = "3";
        this.startText.isVisible = true;
        setTimeout(() => { this.startText.text = "2" }, 1000);
        setTimeout(() => { this.startText.text = "1" }, 2000);
        setTimeout(() => { this.startText.text = "GO!" }, 3000);
        setTimeout(() => { this.startText.isVisible = false }, 4000);
    }

}