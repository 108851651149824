import { gameNodes } from "../../engine/scene/obstacles/ObstacleFactory";
import { trackEditorState } from "../../state/TrackEditorState";

export const Dropables = () => {
  return (
    <div className="dropables">
      {gameNodes.map((node, index) => (
        <div
          key={index}
          className="dropable"
          draggable={true}
          onDragStart={(e) => {
            trackEditorState.emit("ui:node:dragstart", node);

            e.dataTransfer.setDragImage(new Image(), 0, 0);
          }}
          onClick={() =>
            trackEditorState.emit("ui:node:click", node.name, node.data)
          }
        >
          <img
            className="dropable-img"
            src={node.image}
            draggable={false}
            alt={node.title}
          />
          <div className="dropable-title">{node.title}</div>
        </div>
      ))}
    </div>
  );
};
