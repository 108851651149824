import { MotorSound } from "./MotorSound";


export class SoundEngine {
  private motor1Sound: MotorSound;
  // private motor2Sound: MotorSound;
  // private motor3Sound: MotorSound;
  // private motor4Sound: MotorSound;
  constructor() {
    this.motor1Sound = new MotorSound();
    // this.motor2Sound = new MotorSound();
    // this.motor3Sound = new MotorSound();
    // this.motor4Sound = new MotorSound();
  }
  setRPMs(rpm1: number, rpm2: number, rpm3: number, rpm4: number) {

    this.motor1Sound.setRPM(rpm1);
    // this.motor2Sound.setRPM(rpm2);
    // this.motor3Sound.setRPM(rpm3);
    // this.motor4Sound.setRPM(rpm4);
  }
  start() {
    this.motor1Sound.start();
    // this.motor2Sound.start();
    // this.motor3Sound.start();
    // this.motor4Sound.start();
  }
  stop() {
    this.motor1Sound.stop();
    // this.motor2Sound.stop();
    // this.motor3Sound.stop();
    // this.motor4Sound.stop();
  }
}

export const soundEngine = new SoundEngine();