import { DroneData } from "../../types/drone";

export const drones: DroneData[] = [
  {
    id: "",
    aditionalWeight: 20,
    aerodynamics: {
      frontalDrag: {
        area: 0.013,
        coefficient: 0.8,
      },
      sideDrag: {
        area: 0.013,
        coefficient: 0.8,
      },
      topDrag: {
        area: 0.013,
        coefficient: 0.8,
      },
    },
    proppelers: {
      brend: "",
      id: "",
      name: "HQ 5x4x4",
      size: 5,
      weight: 5,
    },
    thrust: {
      id: "",
      motorId: "",
      propId: "",
      maxT: 1450,
      maxA: 39.69,
      maxRPM: 28122,
      idleT: 54,
      idleRPM: 5397,
      idleA: 1.61,
    },
    name: "Amaxinno Racing 5 2305",
    motor: {
      id: "",
      name: "",
      weight: 31.6,
      brend: "Amaxinno",
      maxS: 6,
      minS: 2,
    },
    battery: {
      name: "CNHL MiniStar 1500mAh",
      id: "",
      weight: 170,
      brend: "CNHL",
      capacity: 1500,
      dischargeRate: 120,
      dischargeRateBurst: 240,
      s: 4,
    },
    frame: {
      id: "",
      brend: "Amaxinno",
      name: 'Amaxinno Racing 5" Frame',
      maxPropsSize: 5.1,
      weight: 66,
      width: 0.13789,
      depth: 0.13789,
      height: 0.05,
      maxCameraAngle: 50,
      minCameraAngle: 0,
    },
  },
];
