import {
    Color3,
    Mesh,
    MeshBuilder,
    MultiMaterial,
    Scene,
    StandardMaterial,
    SubMesh,
    TransformNode,
    Vector3
} from "@babylonjs/core";
import { Arrow } from "./misc/Arrow";
import { GatesConstants } from "./multigp/GatesConstants";

export class Checkpoint extends TransformNode {
    public checkpoint: Mesh;
    public isHelper: boolean = false;
    private arrow: Arrow;
    constructor(name: string, options: { shape: "square" | "circle", width?: number, height?: number, depth: number, isHelper?: boolean }, private scene: Scene,) {
        super(`Chekpoint: ${name}`, scene);
        const depth = GatesConstants.DEPTH;
        this.isHelper = options.isHelper || false;
        const { width, height } = options;
        const greenNeonMaterial = new StandardMaterial("GreenNeonMaterial", scene);
        greenNeonMaterial.diffuseColor = new Color3(0, 1, 0); // Green
        greenNeonMaterial.emissiveColor = new Color3(0, 1, 0); // Glow effect
        greenNeonMaterial.alpha = 0.3; // Transparent effect
        greenNeonMaterial.backFaceCulling = true; // Always show the neon effect
        const redMaterial = new StandardMaterial("RedMaterial", scene);
        redMaterial.diffuseColor = new Color3(1, 0, 0); // Red
        redMaterial.alpha = 0.5; // Transparent effect

        // Add a glow layer for the neon effect
        // const glowLayer = new GlowLayer("glow", scene);
        // glowLayer.intensity = 0.2; // Adjust intensity as needed
        // glowLayer.addIncludedOnlyMesh(this.checkpoint);

        // Create a MultiMaterial for the checkpoint
        const multiMaterial = new MultiMaterial("MultiMaterial", scene);
        multiMaterial.subMaterials.push(greenNeonMaterial); // Green neon material
        multiMaterial.subMaterials.push(redMaterial); // Red transparent material

        // Assign the MultiMaterial to the checkpoint mesh
        if (options.shape === "circle") {
            this.checkpoint = MeshBuilder.CreateCylinder(`Chekpoint: ${name}`, { diameter: width, height, updatable: false }, scene);
            this.checkpoint.rotation.x = Math.PI / 2;

        } else {
            this.checkpoint = MeshBuilder.CreateBox(`Chekpoint: ${name}`, { width, height, depth: depth, updatable: false }, scene);
            this.checkpoint.position.y = height! / 2;
            this.checkpoint.material = multiMaterial;
            const verticesCount = this.checkpoint.getTotalVertices();
            new SubMesh(0, 0, verticesCount, 6, 12, this.checkpoint); // Red material
            new SubMesh(1, 0, verticesCount, 0, 6, this.checkpoint); // Green neon material
        }

        this.arrow = new Arrow(`Arrow: ${name}`, scene);
        this.arrow.setParent(this);
        this.arrow.position.y = (height ?? 1) / 2;
        this.arrow.rotation.x = -this.checkpoint.rotation.x;
        this.checkpoint.setParent(this);
        // this.checkpoint.visibility = 0.3; 
        // this.checkpoint.visibility = 0;
        // if (this.isHelper) {
        //     this.checkpoint.visibility = 0;
        // }
    }
    setVisibile(visible: boolean) {
        this.checkpoint.visibility = visible ? 0.3 : 0;
    }
    getPointBefore(distance: number): Vector3 {
        return this.getWorldCenter().add(this.getWorldForward().scale(distance))
    }
    getPointAfter(distance: number): Vector3 {
        return this.getWorldCenter().add(this.getWorldForward().scale(-distance))
    }
    getWorldForward(): Vector3 {
        if (!this.checkpoint) {
            return Vector3.Zero();
        }
        const worldForward = new Vector3();
        this.checkpoint.getDirectionToRef(new Vector3(0, 0, -1), worldForward);
        return worldForward;
    }
    getWorldCenter(): Vector3 {
        if (!this.checkpoint) {
            return Vector3.Zero();
        }
        let boundingInfo = this.checkpoint.getBoundingInfo();
        return boundingInfo.boundingBox.centerWorld;
    }
}
