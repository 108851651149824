export type RateType =
  | "quickrates"
  | "actual"
  | "betaflight"
  | "raceflight"
  | "kiss";

export type RatePropType = {
  key: RateValueKeys;
  name: string;
  min?: number;
  max?: number;
  step?: number;
};

export type QuickRateValue = {
  qrRcRate?: number;
  qrMaxRate?: number;
  qrExpo?: number;
};
export type ActualRateValue = {
  acCenterSensitivity?: number;
  acMaxRate?: number;
  acExpo?: number;
};
export type BetaflightRateValue = {
  bfRcRate?: number;
  bfRate?: number;
  bfRcExpo?: number;
};
export type KissRateValue = {
  kissRcRate?: number;
  kissRate?: number;
  kissRcCurve?: number;
};
export type RafeflightValue = {
  rfRate?: number;
  rfAcro?: number;
  rfExpo?: number;
};
export type MixedRateValue = QuickRateValue &
  BetaflightRateValue &
  ActualRateValue &
  KissRateValue &
  RafeflightValue;
export type RateValueKeys = keyof MixedRateValue;

export type RateProfileType = {
  id: string;
  name: string;
  type: RateType;
  values: {
    [key: string]: QuickRateValue &
      BetaflightRateValue &
      KissRateValue &
      ActualRateValue &
      RafeflightValue;
  };
};
export const rateTypes: {
  name: string;
  key: RateType;
  props: RatePropType[];
}[] = [
  {
    key: "betaflight", name: "Betaflight", props: [
      { key: "bfRcRate", name: "RC Rate", min: 0.01, max: 2.55, step: 0.01 },
      { key: "bfRate", name: "Rate", min: 0, max: 1, step: 0.01 },
      { key: "bfRcExpo", name: "RC Expo", min: 0, max: 1, step: 0.01 },
    ],
  },{ 
    key: "raceflight", name: "RaceFlight", props: [
      { key: "rfRate", name: "Rate", min: 10, max: 2000, step: 10 },
      { key: "rfAcro", name: "Acro+", min: 0, max: 255, step: 1 },
      { key: "rfExpo", name: "Expo", min: 0, max: 100, step: 1 },
    ]
  }, {
    key: "kiss", name: "KISS", props: [
      { key: "kissRcRate", name: "RC Rate", min: 0.01, max: 2.55, step: 0.01 },
      { key: "kissRate", name: "Rate", min: 0, max: 0.99, step: 0.01 },
      { key: "kissRcCurve", name: "RC Curve", min: 0, max: 1, step: 0.01 },
    ],
  }, {
    key: "actual", name: "Atual", props: [
      { key: "acCenterSensitivity", name: "Center Sensitivity", min: 10, max: 2000, step: 10, },
      { key: "acMaxRate", name: "Max Rate", min: 10, max: 2000, step: 10 },
      { key: "acExpo", name: "Expo", min: 0, max: 1, step: 0.01 },
    ],
  }, {
    key: "quickrates", name: "Quick Rates", props: [
      { key: "qrRcRate", name: "RC Rate", min: 0.01, max: 2.55, step: 0.01},
      { key: "qrMaxRate", name: "Max Rate", min: 10, max: 2000, step: 10},
      { key: "qrExpo", name: "Expo", min: 0, max: 1, step: 0.01 },
    ],
  }
];
