import { Scene } from "@babylonjs/core";
import { gameSettings } from "../../state/GameSettings";
import { gameState } from "../../state/GameState";
import { Drone } from "../logic/Drone";
import { IPlugin } from "./IPlugin";
export class DroneUpdatePlugin implements IPlugin {
    constructor(private scene: Scene) {

    }
    load(): void {

        gameState.on("drone:loaded", this.onDroneLoaded.bind(this));
        gameSettings.on("settings:changed", this.onSettingsChanged.bind(this));

    }
    dispose(): void {
        gameState.off("drone:loaded", this.onDroneLoaded.bind(this));
        gameSettings.off("settings:changed", this.onSettingsChanged.bind(this));
    }
    applyUserSettings() {
        const drone = this.scene.getNodeById("player") as Drone;
        if (drone) {
            drone.setCameraAngle(gameSettings.cameraAngle);
            drone.calculateFov();
        }
    }
    onDroneLoaded() {
        this.applyUserSettings();
    }
    onSettingsChanged(): void {
        this.applyUserSettings();
    }
}