import { TRYPValues } from "./util";

export const defaultAxisMappings: { [key: string]: TRYPValues<number> } = {
    "Seeed XIAO M0 (Vendor: 2886 Product: 802f)": {
        throttle: 2,
        roll: 0,
        yaw: 3,
        pitch: 1
    }
};

export const getDefaultAxisMapping = (gamepadId: string) => {
    let mapping = defaultAxisMappings[gamepadId];
    console.log(mapping);
    if (!mapping) {
        mapping = {
            throttle: 0,
            yaw: 1,
            pitch: 2,
            roll: 3,
        };
    }
    return mapping;
}