import { Ray, Scene, Vector3 } from "@babylonjs/core";
import { gameState } from "../../state/GameState";
import { Drone } from "../logic/Drone";
import { Checkpoint } from "../scene/obstacles/Checkpoint";
import { Obstacle } from "../scene/obstacles/Obstacle";
import { IPlugin } from "./IPlugin";

export class CollisionPlugin implements IPlugin {
    constructor(private scene: Scene) {

    }
    private previousPosition?: Vector3;
    load(): void {
        this.scene.onBeforeRenderObservable.add(this.onBeforeRender.bind(this));

    }
    dispose(): void {
        this.scene.onBeforeRenderObservable.removeCallback(this.onBeforeRender.bind(this));
    }
    onBeforeRender(scene: Scene) {
        const drone = scene.getTransformNodeByName("player") as Drone;
        if (!drone) return;
        if (!this.previousPosition) {
            this.previousPosition = drone.mesh.position.clone();
            return;
        }
        const currentPosition = drone.mesh.position.clone();
        const distance = currentPosition.subtract(this.previousPosition).length();
        let direction = currentPosition.subtract(this.previousPosition).normalize();
        let ray = new Ray(this.previousPosition, direction, distance * 2);
        let hit = scene.pickWithRay(ray, (mesh) => {
            return mesh.name !== "drone" && mesh.name !== "ground";
        }, false);
        if (hit && hit.hit) {
            //console.log(`hit: ${hit.pickedMesh?.name}, distance: ${distance * 2}`);
            const parent = hit.pickedMesh?.parent;
            if (parent) {
                if (parent instanceof Obstacle) {
                    console.log("hit obstacle");
                    let velocity = drone.mesh.physicsImpostor?.getLinearVelocity();
                    if (velocity) {
                        // Random force from -0.1 to -0.5
                        let force = velocity.scale(-0.3);
                        // let force = velocity.scale(Math.random() * -1 - 0.5);

                        // Apply the force at the drone's position
                        drone.mesh.physicsImpostor?.applyImpulse(force, drone.mesh.position.clone());
                        // add random rotation
                        //drone.mesh.physicsImpostor?.applyImpulse(new Vector3(Math.random() * 0.5, Math.random() * 0.5, Math.random() * 0.5), drone.mesh.position.clone());
                    }
                    // drone.mesh.physicsImpostor?.applyImpulse(force, drone.mesh.position.clone());
                } else if (parent instanceof Checkpoint) {
                    console.log("hit checkpoint");
                    gameState.emit("checkpoint:passed", parent);
                } else {
                    let velocity = drone.mesh.physicsImpostor?.getLinearVelocity();
                    if (velocity) {
                        // Random force from -0.1 to -1
                        let force = velocity.scale(-0.7);
                        // let force = velocity.scale(Math.random() * -1 - 0.5);

                        // Apply the force at the drone's position
                        drone.mesh.physicsImpostor?.applyImpulse(force, drone.mesh.position.clone());
                        // add random rotation
                        //drone.mesh.physicsImpostor?.applyImpulse(new Vector3(Math.random() * 0.5, Math.random() * 0.5, Math.random() * 0.5), drone.mesh.position.clone());
                    }
                }
            }
        }
        this.previousPosition = currentPosition.clone();
    }


}