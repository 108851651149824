import {
  Scene,
  Vector3
} from "@babylonjs/core";
import { Obstacle } from "../Obstacle";
import { getGatesSizes } from "./GatesConstants";
import { MGPGate } from "./MGPGate";
import { MGPTower } from "./MGPTower";

export class MGPOffsetGate extends Obstacle {
  constructor(name: string, scene: Scene, isBig: boolean = false) {
    super(`MGPOffsetGate${isBig ? '7x6' : '5x5'}`, scene);
    const { width } = getGatesSizes(isBig);

    const gate = new MGPGate("gate", scene, isBig);
    gate.position = new Vector3(- width / 2, 0, 0);
    gate.setParent(this);

    const tower = new MGPTower("tower", scene, isBig);
    tower.position = new Vector3(0, 0, width / 2);
    tower.rotate(Vector3.Up(), Math.PI / 2);
    tower.setParent(this);


    this.coliders.push(...gate.coliders, ...tower.coliders);
    this.checkpoints.push(...gate.checkpoints, ...tower.checkpoints);
  }
}
