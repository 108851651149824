import { Effect } from "@babylonjs/core";


export const initNeonCityMaterialShaders = () => {
    Effect.ShadersStore["neonCityMaterialVertexShader"] = `
precision highp float;

// Attributes
attribute vec3 position;
attribute vec3 normal;

// Uniforms
uniform mat4 worldViewProjection;

// Varying
varying vec3 vPosition;
varying vec3 vNormal;

void main(void) {
    vPosition = position; // Pass local position
    vNormal = normalize(normal); // Pass local normal
    gl_Position = worldViewProjection * vec4(position, 1.0);
}
`;
    Effect.ShadersStore["neonCityMaterialFragmentShader"] = `
precision highp float;

uniform float size;
uniform float time;
uniform vec3 scale; // Scaling factors

varying vec3 vPosition;
varying vec3 vNormal;

// Improved hash function using 3D input
float hash(vec3 p) {
    p = fract(p * 0.1031);
    p += dot(p, p.yzx + 33.33);
    return fract((p.x + p.y) * p.z);
}

// Function to generate a pseudo-random number based on input
float random(vec3 st) {
    return hash(st);
}

// RGB to HSL conversion
vec3 rgb2hsl(vec3 color) {
    float maxc = max(max(color.r, color.g), color.b);
    float minc = min(min(color.r, color.g), color.b);
    float l = (maxc + minc) / 2.0;
    float s = 0.0;
    float h = 0.0;

    if (maxc != minc) {
        float d = maxc - minc;
        s = l < 0.5 ? d / (maxc + minc) : d / (2.0 - maxc - minc);

        if (maxc == color.r) {
            h = (color.g - color.b) / d + (color.g < color.b ? 6.0 : 0.0);
        } else if (maxc == color.g) {
            h = (color.b - color.r) / d + 2.0;
        } else {
            h = (color.r - color.g) / d + 4.0;
        }
        h /= 6.0;
    }
    return vec3(h, s, l);
}

// HSL to RGB conversion
float hue2rgb(float p, float q, float t) {
    if (t < 0.0) t += 1.0;
    if (t > 1.0) t -= 1.0;
    if (t < 1.0 / 6.0) return p + (q - p) * 6.0 * t;
    if (t < 1.0 / 2.0) return q;
    if (t < 2.0 / 3.0) return p + (q - p) * (2.0 / 3.0 - t) * 6.0;
    return p;
}

vec3 hsl2rgb(vec3 hsl) {
    float r, g, b;
    float h = hsl.x;
    float s = hsl.y;
    float l = hsl.z;

    if (s == 0.0) {
        r = g = b = l; // Achromatic
    } else {
        float q = l < 0.5 ? l * (1.0 + s) : l + s - l * s;
        float p = 2.0 * l - q;
        r = hue2rgb(p, q, h + 1.0 / 3.0);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1.0 / 3.0);
    }
    return vec3(r, g, b);
}

// Function to generate a random soft color
vec3 randomSoftColor(vec3 st) {
    // Generate random RGB color
    vec3 color = vec3(
        random(st + vec3(1.0, 0.0, 0.0)),
        random(st + vec3(0.0, 1.0, 0.0)),
        random(st + vec3(0.0, 0.0, 1.0))
    );

    // Convert RGB to HSL
    vec3 hsl = rgb2hsl(color);

    // Adjust saturation and lightness for softer colors
    hsl.y *= 0.5; // Reduce saturation to 50%
    hsl.z = mix(hsl.z, 0.8, 0.5); // Increase lightness towards 0.8

    // Convert back to RGB
    color = hsl2rgb(hsl);

    return color;
}

void main(void) {
    // Adjusted positions to compensate for scaling
    vec3 adjustedPosition = vPosition * scale;

    // Calculate positions on different planes
    vec2 squareCoords;
    vec3 coords;
    vec3 weights = abs(vNormal);
    weights /= dot(weights, vec3(1.0));
    float maxWeight = max(weights.x, max(weights.y, weights.z));

    if (maxWeight == weights.z) {
        coords = vec3(adjustedPosition.xy / size, vNormal.z);
        squareCoords = fract(adjustedPosition.xy / size);
    } else if (maxWeight == weights.y) {
        coords = vec3(adjustedPosition.xz / size, vNormal.y);
        squareCoords = fract(adjustedPosition.xz / size);
    } else {
        coords = vec3(adjustedPosition.yz / size, vNormal.x);
        squareCoords = fract(adjustedPosition.yz / size);
    }

    // Generate a random value per square
    float rnd = random(floor(coords));

    // Decide if the square is active based on the random number
    float threshold = 0.06; // 6% chance to be active
    float activeMask = step(rnd, threshold);

    // Per-square phase to desynchronize transitions (use a different seed)
    float phase = random(floor(coords) + vec3(100.0));

    // Define transition parameters
    float period = 5.0; // Duration of the full on-off cycle in seconds
    float transitionDuration = 2.5; // Duration of the transition phase in seconds

    // Compute the per-square time within the period
    float t = mod(time + phase * period, period);

    // Compute activation value for active squares
    float activationValueActive = 0.0;

    float isTransitionIn = step(0.0, t) * (1.0 - step(transitionDuration, t));
    float isFullyOn = step(transitionDuration, t) * (1.0 - step(period - transitionDuration, t));
    float isTransitionOut = step(period - transitionDuration, t);

    activationValueActive = isTransitionIn * smoothstep(0.0, transitionDuration, t)
                          + isFullyOn * 1.0
                          + isTransitionOut * smoothstep(period, period - transitionDuration, t);

    // Final activation value
    float activationValue = activationValueActive * activeMask;

    // Generate colors
    vec3 onColor = randomSoftColor(floor(coords)); // Use the new function for softer colors
    float grayValue = random(floor(coords)) * 0.2 + 0.1; 
    vec3 offColor = vec3(grayValue);

    // Compute glow effect
    vec2 center = vec2(0.5, 0.5);
    float dist = distance(squareCoords, center);
    float glow = smoothstep(0.5, 0.0, dist); // Adjust the glow intensity and radius as needed
    vec3 glowColor = onColor * (1.0 + glow * 0.5); // Increase brightness towards center

    // Interpolate between 'off' and 'on' colors using activationValue
    vec3 color = mix(offColor, glowColor, activationValue);

    // Border logic
    float borderWidth = 0.03; // Adjust the border width as needed
    if (squareCoords.x < borderWidth || squareCoords.x > 1.0 - borderWidth ||
        squareCoords.y < borderWidth || squareCoords.y > 1.0 - borderWidth) {
        color = vec3(0.0); // Border color, e.g., black
    }

    gl_FragColor = vec4(color, 1.0);
}

`;
}