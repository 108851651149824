import { Effect } from "@babylonjs/core";

Effect.ShadersStore["noise"] =
    `#extension GL_OES_standard_derivatives : enable

precision highp float;

uniform float time;
float PHI = 1.61803398874989484820459;



float gold_noise(in vec2 xy, in float seed){
       return fract(tan(distance(xy*PHI, xy)*seed)*xy.x);
}

void main( void ) {
	
	float seed = fract(time);
	float color = fract((sin(dot(gl_FragCoord.xy ,vec2(0.9898,78.233)))) * time);
	gl_FragColor = vec4 (gold_noise(gl_FragCoord.xy, seed+0.1),  // r
                       gold_noise(gl_FragCoord.xy, seed+0.2),  // g
                       gold_noise(gl_FragCoord.xy, seed+0.3),  // b
                       gold_noise(gl_FragCoord.xy, seed+0.4));
	//gl_FragColor = vec4(vec3(color),1.0);
}`