import { Scene } from "@babylonjs/core";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { Obstacle } from "../engine/scene/obstacles/Obstacle";

export const findObstacleBounds = (scene: Scene) => {
    let minX = 0,maxX = 0, minZ = 0, maxZ = 0;

    scene.transformNodes.forEach(mesh => {
        if (mesh instanceof Obstacle) {
            const { x, z } = (mesh as TransformNode).getAbsolutePosition();
            if (minX === undefined || x < minX) minX = x;
            if (maxX === undefined || x > maxX) maxX = x;
            if (minZ === undefined || z < minZ) minZ = z;
            if (maxZ === undefined || z > maxZ) maxZ = z;
        }
    });
    minX = minX || 0;
    maxX = maxX || 0;
    minZ = minZ || 0;
    maxZ = maxZ || 0;

    return { minX, maxX, minZ, maxZ };
}
export const getGroundBounds = (scene: Scene) => {
    let minX, maxX, minZ, maxZ;

    const ground = scene.getMeshById("ground");
    if (ground) {
        const { x: xmin, z: zmin } = ground.getBoundingInfo().boundingBox.minimum;
        const { x: xmax, z: zmax } = ground.getBoundingInfo().boundingBox.maximum;
        minX = xmin;
        maxX = xmax;
        minZ = zmin;
        maxZ = zmax;
    }
    minX = minX || 0;
    maxX = maxX || 0;
    minZ = minZ || 0;
    maxZ = maxZ || 0;

    return { minX, maxX, minZ, maxZ };
}