import { AMOscillator, Distortion, Filter, Gain, Noise } from "tone";

export class MotorSound {
  fundamental: AMOscillator;
  fundamentalSine: AMOscillator;
  harmonic1: AMOscillator;
  harmonic2: AMOscillator;
  noiseGain: Gain;
  noiseFilter: Filter;
  noise: Noise;

  constructor() {
    this.fundamental = new AMOscillator({
      frequency: 100, // Placeholder, to be updated with RPM
      type: "sawtooth",
    }).toDestination();
    this.fundamentalSine = new AMOscillator({
      frequency: 150, // Placeholder, to be updated with RPM
      type: "sine",
    }).toDestination();

    this.harmonic1 = new AMOscillator({
      frequency: 200, // 2x fundamental
      type: "triangle17",
    }).toDestination();

    this.harmonic2 = new AMOscillator({
      frequency: 300, // 3x fundamental
      type: "sine32",
    }).toDestination();

    // Noise for air turbulence
    this.noise = new Noise("white");
    this.noiseGain = new Gain(0.2).toDestination();
    this.noiseFilter = new Filter(600, "bandpass").toDestination();
    this.noise.connect(this.noiseGain);
    this.noise.connect(this.noiseGain).connect(this.noiseFilter);

    const distortion = new Distortion(0.3).toDestination();
    this.fundamental.connect(distortion);
    this.fundamentalSine.connect(distortion);
    this.harmonic1.connect(distortion);
    this.harmonic2.connect(distortion);
  }
  setRPM(rpm: number) {
    const baseFrequency = (rpm / 60) * 1.5; // Convert RPM to Hz (simplified)
    const noiseFrequency = baseFrequency * 2;
    this.fundamental.frequency.rampTo(baseFrequency, 0.05);
    this.fundamentalSine.frequency.rampTo(baseFrequency * 1.5, 0.05);
    this.harmonic1.frequency.rampTo(baseFrequency * 2, 0.05);
    this.harmonic2.frequency.rampTo(baseFrequency * 3, 0.05);

    this.noiseFilter.frequency.rampTo(noiseFrequency, 0.05);

    const amplitude = Math.min(rpm / 10000, 1); // Scale amplitude
    this.noiseGain.gain.rampTo(amplitude * 0.3, 0.05);
  }
  start() {
    this.fundamental.start();
    this.fundamentalSine.start();
    this.harmonic1.start();
    this.harmonic2.start();
    this.noise.start()
  }
  stop() {
    this.fundamental.stop();
    this.fundamentalSine.stop();
    this.harmonic1.stop();
    this.harmonic2.stop();
    this.noise.stop();

  }
}
