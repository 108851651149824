import { convertRange } from "../../util/range";

export enum RcStickModes {
    MODE1 = "mode1",
    MODE2 = "mode2",
    MODE3 = "mode3",
    MODE4 = "mode4",
}


export type SticksValues<T> = {
    LX: T;
    LY: T;
    RX: T;
    RY: T;
}
export type TRYPValues<T> = {
    throttle: T;
    roll: T;
    yaw: T;
    pitch: T;
}
export const stickValueGenerator = <T>(value: T) => ({
    LX: value,
    LY: value,
    RX: value,
    RY: value,
});
export const trypValueGenerator = <T>(value: T) => ({
    throttle: value,
    roll: value,
    yaw: value,
    pitch: value,
});

const normalizeAxisValue = (value: number, min: number, max: number, mid: number, deadzone: number, invert: boolean) => {
    if (value < min) value = min;
    if (value > max) value = max;

    if (value < deadzone && value > -deadzone) {
        value = 0;
    }
    if (value < mid) {
        value = convertRange(value, min, mid, -1, 0);
    }
    if (value > mid) {
        value = convertRange(value, mid, max, 0, 1);
    }

    if (invert) {
        value *= -1;
    }

    return value;
};

export const normalizeStickValues = (values: SticksValues<number>, settings: { min: SticksValues<number>, max: SticksValues<number>, mid: SticksValues<number>, deadzones: SticksValues<number>, invert: SticksValues<boolean> }) => {
    const { min, max, mid, deadzones, invert } = settings;
    return {
        LX: normalizeAxisValue(values.LX, min.LX, max.LX, mid.LX, deadzones.LX, invert.LX),
        LY: normalizeAxisValue(values.LY, min.LY, max.LY, mid.LY, deadzones.LY, invert.LY),
        RX: normalizeAxisValue(values.RX, min.RX, max.RX, mid.RX, deadzones.RX, invert.RX),
        RY: normalizeAxisValue(values.RY, min.RY, max.RY, mid.RY, deadzones.RY, invert.RY),
    };
};
export const normalizeTrypValues = (values: TRYPValues<number>, settings: { min: TRYPValues<number>, max: TRYPValues<number>, mid: TRYPValues<number>, deadzones: TRYPValues<number>, invert: TRYPValues<boolean> }) => {
    const { min, max, mid, deadzones, invert } = settings;
    return {
        throttle: normalizeAxisValue(values.throttle, min.throttle, max.throttle, mid.throttle, deadzones.throttle, invert.throttle),
        roll: normalizeAxisValue(values.roll, min.roll, max.roll, mid.roll, deadzones.roll, invert.roll),
        yaw: normalizeAxisValue(values.yaw, min.yaw, max.yaw, mid.yaw, deadzones.yaw, invert.yaw),
        pitch: normalizeAxisValue(values.pitch, min.pitch, max.pitch, mid.pitch, deadzones.pitch, invert.pitch),
    };
}

export const axesToTRYP = (axes: { [key: number]: number }, mapping: TRYPValues<number>) => {
    return {
        throttle: axes[mapping.throttle],
        roll: axes[mapping.roll],
        yaw: axes[mapping.yaw],
        pitch: axes[mapping.pitch],
    }
}

export const sticksToTRYP = <T>(sticks: SticksValues<T>, mode: RcStickModes) => {
    if (mode === RcStickModes.MODE1) {
        return {
            throttle: sticks.LY,
            roll: sticks.LX,
            yaw: sticks.RX,
            pitch: sticks.RY,
        }
    }
    if (mode === RcStickModes.MODE2) {
        return {
            throttle: sticks.LY,
            roll: sticks.RX,
            yaw: sticks.LX,
            pitch: sticks.RY,
        }
    }
    if (mode === RcStickModes.MODE3) {
        return {
            throttle: sticks.RY,
            roll: sticks.LX,
            yaw: sticks.RX,
            pitch: sticks.LY,
        }
    }
    if (mode === RcStickModes.MODE4) {
        return {
            throttle: sticks.RY,
            roll: sticks.RX,
            yaw: sticks.LX,
            pitch: sticks.LY,
        }
    }
}